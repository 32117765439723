import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CheckBox, DropButton, RadioButton } from 'grommet';
import { Close, Down } from 'grommet-icons';
import styled from 'styled-components';

const MyDropButton = styled(DropButton)`
   border-radius: 0px
`;
const MyButton = styled(Button)`
   border-radius: 0px
`;

export class Selector extends Component {
   static propTypes = {
      options: PropTypes.any,
      checked: PropTypes.any,
      label: PropTypes.string,
      handleSelectorChange: PropTypes.func,
      changeFilter: PropTypes.func,
      activeFilter: PropTypes.string,
      disabled: PropTypes.bool,
   }
   static defaultProps = {
      options: [],
      checked: [],
      label: '',
      activeFilter: 'a'
   }

   constructor(props, context) {
      super(props, context);

      this.state = {
         open: false,
      }
   }

   onCheckAll = event => {
      const { label, handleSelectorChange, options: checkboxes } = this.props;
      if (event.target.checked) {
         handleSelectorChange(label.toLowerCase(), checkboxes);
      } else {
         handleSelectorChange(label.toLowerCase(), []);
      }
   };

   onCheck = (event, value) => {
      const { label, handleSelectorChange, checked } = this.props;
      if (event.target.checked) {
         handleSelectorChange(label.toLowerCase(), checked.concat(value));
      } else {
         handleSelectorChange(label.toLowerCase(), checked.filter(i => i !== value));
      }
   };

   render() {
      const { label, checked, options: checkboxes, changeFilter, activeFilter, disabled } = this.props;
      const { open } = this.state;

      return (
         <div>
         {activeFilter === label ?
         <MyDropButton
            label={
               <Box direction='row' gap='xsmall'>
                  <RadioButton name={label} label={label} readOnly={true}
                     checked={activeFilter === label}
                  />
                  {activeFilter === label ? <Down /> : null}
               </Box>
            }
            open={open}
            disabled={disabled}
            onClose={() => this.setState({ open: false })}
            onOpen={() => this.setState({ open: true })}
            dropAlign={{ top: 'bottom', left: 'left' }}
            dropContent={
            <Box align="start" overflow='scroll'>
               <Button onClick={() => this.setState({ open: false })}
                  icon={<Close />}
               />
               <CheckBox
                  checked={checked.length === checkboxes.length}
                  indeterminate={checked.length > 0 && checked.length < checkboxes.length}
                  label="All"
                  onChange={this.onCheckAll}
               />
               {checkboxes.map((i, x) => (
                  <CheckBox
                     key={i+x}
                     checked={checked.indexOf(i) !== -1}
                     label={i}
                     onChange={e => this.onCheck(e, i)}
                  />
               ))}
            </Box>
            }
         /> :
         <MyButton
            label={
               <RadioButton name={label} label={label} readOnly={true}
                  checked={false}
               />}
            disabled={disabled}
            onClick={() => changeFilter(label)}
         />}
         </div>
      );
   }
}
