import { sendNotification } from '../../../api/notification';
// GraphQL query files
import { loader } from 'graphql.macro';
const LOAD_LIFT_QUERY = loader('./Load.graphql');
const LOAD_TOTALS_QUERY = loader('./Totals.graphql');
const LOAD_CROSSTAB_QUERY = loader('./Crosstab.graphql');
const LOAD_EXP_FILTER = loader('./ExpFilter.graphql');
const LOAD_EXP_TYPE = loader('./ExpTypes.graphql');

// Actions
const SET_LOADING = 'lifting/SET_LOADING';
const LOAD = 'lifting/LOAD';
const TOTALS = 'lifting/TOTALS';
const COMPTOTALS = 'lifting/COMPTOTALS';
const CROSSTAB = 'lifting/CROSSTAB';
const EXP_FILTER = 'lifting/EXPFILTER';
const EXP_TYPE = 'lifting/EXPTYPE';

// Reducer
const initialState = {
   data: undefined,
   workingData: undefined,
   companyTotals: undefined,
   totals: undefined,
   workingTotals: undefined,
   crosstab: undefined,
   crossLease: undefined,
   expFilter: undefined,
   expType: undefined,
   isLoading: false,
}
export default function reducer(state = initialState, action = {}) {
   const { type, payload } = action;
   switch (type) {
      case SET_LOADING:
      case LOAD:
      case TOTALS:
      case COMPTOTALS:
      case CROSSTAB:
      case EXP_FILTER:
      case EXP_TYPE:
         return { ...state, ...payload }
      default: return state;
   }
}


// Action Creators
export function setIsLoading(isLoading = false) {
   return ({
      type: SET_LOADING,
      payload: { isLoading }
   })
}

export function setLift(data) {
   return({
      type: LOAD,
      payload: { isLoading: false, workingData: data, data }
   });
}

export function setTotals(totals) {
   return({
      type: TOTALS,
      payload: { isLoading: false, workingTotals: totals, totals }
   });
}

export function setCompanyTotals(companyTotals) {
   return({
      type: COMPTOTALS,
      payload: { isLoading: false, companyTotals }
   });
}

export function setCrosstab(crosstab, crossLease) {
   return({
      type: CROSSTAB,
      payload: { isLoading: false, crosstab, crossLease }
   });
}

export function setExpFilter(expFilter) {
   return({
      type: EXP_FILTER,
      payload: { isLoading: false, expFilter }
   });
}

export function setExpType(expType) {
   return({
      type: EXP_TYPE,
      payload: { isLoading: false, expType }
   });
}

export function loadLifting(company_id, aggfield) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.query({
         query: LOAD_LIFT_QUERY,
         variables: { company_id, aggfield }
      }).then((result) => {
         dispatch(setLift(result.data.lift));
      }, (error) => {
         let msg = `Cannot get Lifting Cost Data.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      });
   }
}

export function loadTotals(company_id, aggfield, where_arg) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.query({
         query: LOAD_TOTALS_QUERY,
         variables: { company_id, aggfield, where_arg }
      }).then((result) => {
         dispatch(setTotals(result.data.totals));
      }, (error) => {
         let msg = `Cannot get Lifting Cost Total Data.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      });
   }
}

export function loadCompanyTotals(company_id) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.query({
         query: LOAD_TOTALS_QUERY,
         variables: { company_id, aggfield: 'company', where_arg: null }
      }).then((result) => {
         dispatch(setCompanyTotals(result.data.totals));
      }, (error) => {
         let msg = `Cannot get Lifting Cost Company Total Data.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      });
   }
}

export function loadCrosstab(company_id, lease_arg, where_arg) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.query({
         query: LOAD_CROSSTAB_QUERY,
         variables: { company_id, lease_arg, where_arg }
      }).then((result) => {
         dispatch(setCrosstab(result.data.crosstab, lease_arg));
      }, (error) => {
         let msg = `Cannot get Lifting Cost Company Total Data.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      });
   }
}

// Load specific expense items
export function loadExpFilter(company_id, lease_arg, expcat_arg = 'all', etype_arg = 'all') {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.query({
         query: LOAD_EXP_FILTER,
         variables: { company_id, lease_arg, expcat_arg, etype_arg }
      }).then((result) => {
         dispatch(setExpFilter(result.data.filter.map(i => {
            return {...i, key: `${lease_arg}+${i.exp_cat_num}+${i.date}+${i.exp}`}
         })));
      }, (error) => {
         let msg = `Cannot get Lifting Cost Company Expense Data.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      });
   }
}

export function loadExpType(company_id) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.query({
         query: LOAD_EXP_TYPE,
         variables: { company_id }
      }).then((result) => {
         dispatch(setExpType(result.data.exptypes));
      }, (error) => {
         let msg = `Cannot get Lifting Cost Type Data.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      });
   }
}
