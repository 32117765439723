import React, { useState } from 'react';
import { Box, DataTable, Select } from 'grommet';

export const SummaryTable = ({
   rows,
   handleChartClick,
   handleSummaryChange,
   useColumns,
}) => {
   const [colType, setColType] = useState('Lifting Cost Summary');

   return (
      <Box margin='xsmall'>
         {rows.length > 0 ?
            <Box>
               <Select
                  options={['Lifting Cost Summary', 'Lifting Cost Metrics', 'Chemical Metrics']}
                  value={colType} alignSelf='start'
                  onChange={({option}) => {
                     setColType(option);
                     handleSummaryChange(option);
                  }}
               />
               <DataTable
                  data={rows} sortable={true} size='medium'
                  pad={{ header: 'xsmall', body: 'xxsmall', footer: 'xxsmall'}}
                  columns={useColumns}
                  background={{
                     header: { color: '#499894', dark: true, },
                     body: ['light-1', 'light-3'],
                     footer: 'lightgrey',
                  }}
                  onClickRow={handleChartClick}
               />
            </Box>
         : null}
      </Box>
   );
};

SummaryTable.defaultProps = {
   rows: [],
   useColumns: [],
};
