import React, { Component } from 'react';
import moment from 'moment';

import { Box, Button, CheckBox, Text, Layer, Select, DataTable } from 'grommet';
import { CaretNext, CaretPrevious } from 'grommet-icons';
import { Spinning, DateInput, Form, TextInputField } from 'grommet-controls';

import { Banner } from '../../shared/Banner';

import { connect } from 'react-redux';
import { loadUsers, loadCompanies, newCompany,
   associateUser,  unassociateUser,
   setLicenseStart, setLicenseExpires, setLicenseStatus
} from './api';

class Admin extends Component {
   constructor (props) {
      super(props);

      this.state = {
         companies: [],
         users: [],
         isLoading: false,
         selectedCompany: null,
         selectedUser: null,
         licenseStart: null,
         licenseExpires: null,
         showAddCompany: false,
      }

      this.props.loadCompanies();
      this.props.loadUsers();
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.companies !== this.props.companies) {
         this.setState({ companies: nextProps.companies });
      }
      if (nextProps.users !== this.props.users) {
         this.setState({ users: nextProps.users });
      }
      if (nextProps.isLoading !== this.props.isLoading) {
         this.setState({ isLoading: nextProps.isLoading });
      }
   }

   filterMethod (filter, row, column) {
      const id = filter.id;
      return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
   }

   render() {
      const { companies, users, isLoading, selectedCompany, selectedUser,
         licenseStart, licenseExpires, showAddCompany } = this.state;
      const companyCols = [
         { property: 'checkbox', render: datum => (
            <CheckBox checked={selectedCompany && selectedCompany.id === datum.id} />
         )},
         { property: 'name', header: 'Name', search: true }
      ];

      const userCols = [
         { property: 'checkbox', render: datum => (
            <CheckBox checked={selectedUser && selectedUser.id === datum.id} />
         )},
         { property: 'name', header: 'Name', search: true },
         { property: 'email', header: 'Email', search: true }
      ];

      return(
         <Box gap='medium'>
            <Banner title='OGI Admin Pages'/>
            {isLoading ? <Spinning /> : null}
            <Box direction='row'>
               <Box key='Companies' basis='large' margin={{bottom: 'large'}}>
                  Companies
                  {companies.length > 0 ?
                     <DataTable
                        data={companies} columns={companyCols}
                        sortable={true} size='small'
                        onClickRow={evt => this.setState({
                           selectedCompany: evt.datum,
                           licenseStart: moment(evt.datum.license_start*1).format('MM-DD-YYYY'),
                           licenseExpires: moment(evt.datum.license_expires*1).format('MM-DD-YYYY')
                        })}
                     />
                  : null}
                  <Button
                     label='Add Company'
                     onClick={() => this.setState({showAddCompany: true})}
                  />
                  {showAddCompany && (
                     <Layer
                        onEsc={() => this.setState({showAddCompany: false})}
                        onClickOutside={() => this.setState({showAddCompany: false})}
                     >
                        <Form
                           onSubmit={values => {
                              this.props.newCompany(
                                 parseInt(values.compId),
                                 values.name
                              );
                              this.setState({showAddCompany: false});
                           }}
                        >
                           <TextInputField
                              label='Operator ID'
                              name='compId'
                           />
                           <TextInputField
                              label='Operator Name'
                              name='name'
                           />
                           <Button type='submit' label='Submit' />
                        </Form>
                     </Layer>
                  )}
               </Box>
               <Box key='CompanyDetail'>
                  Company Detail
                  {!selectedCompany ? null :
                     <Box>
                        <Text size='large' alignSelf='center'>
                           {selectedCompany.id} - {selectedCompany.name}
                        </Text>
                        <Box direction='row'>
                           <Box pad='medium'>
                           License Start
                           <DateInput
                              size='small'
                              value={licenseStart}
                              onChange={({target: { value }}) => {
                              this.props.setLicenseStart(
                                 selectedCompany.id,
                                 value
                              );
                              this.setState({licenseStart: moment(value).format('MM-DD-YYYY')});
                              }}
                           />
                           </Box>
                           <Box pad='medium'>
                           License Expires
                           <DateInput
                              size='small'
                              value={licenseExpires}
                              onChange={({target: { value }}) => {
                              this.props.setLicenseExpires(
                                 selectedCompany.id,
                                 value
                              );
                              this.setState({licenseExpires: moment(value).format('MM-DD-YYYY')});
                              }}
                           />
                           </Box>
                        </Box>
                        License Status
                        {selectedCompany.license_status === 3 ? <Text>Paid</Text> : null}
                        {selectedCompany.license_status === 0 ? <Text>Expired</Text> : null}
                        {selectedCompany.license_status === 4 ? <Text>Trial</Text> : null}
                        {selectedCompany.license_status === null ? <Text>Null</Text> : null}
                        <Select
                           options={[
                              { key: 3, label: 'Paid' },
                              { key: 4, label: 'Trial' },
                              { key: 0, label: 'Expired' },
                              { key: null, label: 'NULL' }
                           ]}
                           placeholder='Set a new license type...'
                           labelKey='label'
                           valueKey='key'
                           onChange={(o) => {
                              this.props.setLicenseStatus(
                                 selectedCompany.id,
                                 o.option.key);
                              this.setState({selectedCompany: {...selectedCompany, license_status: o.option.key}});
                           }}
                        />
                     </Box>
                  }
               </Box>
            </Box>
            <Box direction='row'>
               <Box key='Users' basis='large'>
                  UserSettings
                  {users.length > 0 ?
                     <DataTable
                        columns={userCols} data={users} size='medium'
                        onClickRow={evt => this.setState({ selectedUser: evt.datum })}
                     />
                  : null}
               </Box>
               <Box key='Control' basis='xxsmall' alignSelf='center'>
                  <Button
                     icon={<CaretNext/>}
                     disabled={!(this.state.selectedCompany && this.state.selectedUser)}
                     onClick={() => {
                        this.props.associateUser(this.state.selectedUser.id, this.state.selectedCompany.id);
                        this.setState({selectedUser: null});
                     }}
                  />
                  <Button
                     icon={<CaretPrevious/>}
                     disabled={!this.state.selectedUser}
                     onClick={() => {
                        this.props.unassociateUser(this.state.selectedUser.id);
                        this.setState({selectedUser: null});
                     }}
                  />
               </Box>
               <Box key='Association' basis='large'>
                  Association
                  <DataTable
                     columns={userCols} data={users.filter(i => selectedCompany && i.company_id === selectedCompany.id)} size='medium'
                     onClickRow={evt => this.setState({ selectedUser: evt.datum })}
                  />
               </Box>
            </Box>
         </Box>
      )
   }
}


const mapStateToProps = (state) => {
   return {
      companies: state.admin.companies,
      users: state.admin.users,
      isLoading: state.admin.isLoading
   }
}

const mapDispatchToProps = {
   loadUsers, loadCompanies, newCompany,
   associateUser, unassociateUser,
   setLicenseStart, setLicenseExpires, setLicenseStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
