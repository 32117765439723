import React, { Component } from 'react';
import ChartComponent from 'react-chartjs-2';
import './tsgauge';
// Switched from the git-hub version of this library to make local changes.
// import 'chartjs-tsgauge';

export class SpeedGauge extends Component {
   render() {
      return (
         <ChartComponent
            {...this.props}
            ref={ref => this.chartInstance = ref && ref.chartInstance}
            type='tsgauge'
         />
      );
   }
}
