import React, { useState } from 'react';
import { CheckBox, Stack } from 'grommet';
import { OutlabeledPie } from '../../../shared/OutlabeledPie';


const pieOptions = {
   legend: { display: false },
   zoomOutPercentage: 50,
   animation: false,
   maintainAspectRatio: false,
   plugins: {
      outlabels: {
         // Only display outlabels if the slice is more than 1%
         display: ctx => Math.round(ctx.percent*100) > 1 ? true : false,
         text: '%l %p',
         color: 'white',
         stretch: 40,
         font: {
            resizeable: true,
            minSize: 12,
            maxSize: 18
         }
      },
   }
};


export const SummaryPieChart = ({
   compPieData,
   selectorPieData,
   activeFilter
}) => {
   const [isCompPie, setPie] = useState(false);
   return (
   <Stack anchor='bottom-right' fill={true} margin='small'>
      { compPieData && isCompPie ?
         <OutlabeledPie
            data={compPieData}
            options={{...pieOptions,
               title: {
                  display: true,
                  text: 'Company Total',
                  fontSize: 16,
               }
            }}
         />
      : null}
      { selectorPieData && !isCompPie ?
         <OutlabeledPie
            data={selectorPieData}
            options={{...pieOptions,
               title: {
                  display: true,
                  text: `Selected ${activeFilter} Grouping`,
                  fontSize: 16,
               }
            }}
         />
      : null}
      <CheckBox
         checked={isCompPie}
         label='Company'
         toggle={true}
         reverse={true}
         onChange={() => setPie(!isCompPie)}
      />
   </Stack>
)};
