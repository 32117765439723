import React from 'react'
import { Box, TableHeader, TableBody, TableRow, TableCell } from 'grommet'
import { FormDown, FormUp } from 'grommet-icons'

const TABLE_BACKGROUND = {
   'header': '#499894',
   'odd': '#F8F8F8',
   'even': '#EDEDED',
   'footer': '#D3D3D3',
};


export function GrommetTableHeader({ headerGroups, justify=false }) {
   if (!headerGroups) return null;
   // Render JSX for table header with sorting
   return (
      <TableHeader>
         {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
               <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}
                  background={TABLE_BACKGROUND.header}>
                  <Box direction='row' justify={justify ? column.index === 1 ? 'start' : 'end' : undefined}
                  >
                     {column.render('Header')}
                     <span>
                        {column.isSorted
                           ? column.isSortedDesc
                              ? <FormDown />
                              : <FormUp />
                           : ''}
                     </span>
                  </Box>
               </TableCell>
            ))}
            </TableRow>
         ))}
      </TableHeader>
   )
}
export function GrommetTableFilter({ headerGroups, justify=false }) {
   if (!headerGroups) return null;
   // Render JSX for table header with sorting
   return (
      <TableHeader>
         {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
               <TableCell {...column.getHeaderProps()} >
                  {column.canFilter ? column.render('Filter') : null}
               </TableCell>
            ))}
            </TableRow>
         ))}
      </TableHeader>
   )
}

export function GrommetTablePlainBody({ getTableBodyProps, rows, prepareRow }) {
   if (!getTableBodyProps || !rows || !prepareRow) return null;
   // Render JSX for a generic table body
   return (
      <TableBody {...getTableBodyProps()}>
         {rows.map((row, i) => {
            prepareRow(row);
            return(
               <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                     return <TableCell {...cell.getCellProps()} background={i%2 ? TABLE_BACKGROUND.odd : TABLE_BACKGROUND.even}>
                        {cell.render('Cell')}
                     </TableCell>
                  })}
               </TableRow>
            )}
         )}
      </TableBody>
   )
}

export function GrommetTableFooter({ footerGroups }) {
   if (!footerGroups) return null;
   // Render JSX for table footer
   return (
      <TableHeader>
         {footerGroups.map(footerGroup => (
            <TableRow {...footerGroup.getFooterGroupProps()}>
            {footerGroup.headers.map(column => (
               <TableCell {...column.getFooterProps()} background={TABLE_BACKGROUND.footer}>
                  {column.render('Footer')}
               </TableCell>
            ))}
            </TableRow>
         ))}
      </TableHeader>
   )
}
