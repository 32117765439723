import React from 'react'
import { useTable, useSortBy, useExpanded } from 'react-table'
import { Box, Table, TableRow, TableBody, TableCell } from 'grommet'
import { ExpenseTable } from './ExpenseTable'
import { GrommetTableHeader, GrommetTableFooter } from '../../../shared/GrommetTablePieces'
import { TABLE_BACKGROUND } from '../colorConstants'

// Functional Component to allow using React Hooks needed for the react-table framework
export function CrossTable({ columns, data, expFilter }) {
   // Use the state and functions returned from useTable to build your UI
   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      allColumns,
   } = useTable({
      columns,
      data,
      autoResetExpanded: true,
   },
      useSortBy,
      useExpanded,
   );

   // Render the JSX for the table
   return (
      <Table {...getTableProps()}>
         <GrommetTableHeader headerGroups={headerGroups} justify={true}/>
         <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
               prepareRow(row);
               return (
                  <React.Fragment key={row.getRowProps().key}>
                     <TableRow {...row.getRowProps()}>
                     {row.cells.map(cell => {
                        return <TableCell {...cell.getCellProps()} background={i%2 ? TABLE_BACKGROUND.odd : TABLE_BACKGROUND.even}>
                           {cell.render('Cell')}
                        </TableCell>
                     })}
                     </TableRow>
                     {row.isExpanded ? (
                        <TableRow>
                           <TableCell colSpan={allColumns.length}>
                              <Box fill={true}>
                                 <ExpenseTable data={expFilter.filter(i => i.exp_cat_name === row.values.exp_cat_name)} />
                              </Box>
                           </TableCell>
                        </TableRow>
                     ) : null}
                  </React.Fragment>
               )}
            )}
         </TableBody>
         <GrommetTableFooter footerGroups={footerGroups}/>
      </Table>
   )
}
