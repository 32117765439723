import React, { Component } from 'react';

import { Box, Text, DataTable, Grid } from 'grommet';
import { LinkTop } from 'grommet-icons';
import { HorizontalBar, Line } from 'react-chartjs-2';
import MediaQuery from 'react-responsive';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import { OutlabeledPie } from '../../shared/OutlabeledPie';
import { Selector } from '../../shared/Selector';
import { Banner } from '../../shared/Banner';
import DeltaCell from './components/DeltaCell';
import ToggleSwitch from './components/ToggleSwitch';

import { connect } from 'react-redux';
import { loadProduction } from './api';
import decode from 'jwt-decode';
import { AUTH_TOKEN } from '../../shared/constants';

import InfoHelp1 from './production_1.md';

const Tableau20 = ['#4E79A7', '#A0CBE8', '#F28E2B', '#FFBE7D', '#59A14F', '#8CD17D', '#B6992D', '#F1CE63', '#499894', '#86BCB6', '#E15759', '#FF9D9A', '#79706E', '#BAB0AC', '#D37295', '#FABFD2', '#B07AA1', '#D4A6C8', '#9D7660', '#D7B5A6'];


const horizontalOptions = {
   legend: { display: false },
   responsive: true,
   maintainAspectRatio: false,
   animation: false,
   scales: {
      xAxes: [{
         id: 'top',
         position: 'top'
      }]
   },
};

const pieOptions = {
   legend: { display: false },
   zoomOutPercentage: 50,
   animation: false,
   maintainAspectRatio: false,
   title: {
      display: true,
      text: 'Current Prod'
   },
   plugins: {
      outlabels: {
         // Only display outlabels if the slice is more than 1%
         display: ctx => Math.round(ctx.percent*100) > 1 ? true : false,
         text: '%l %p',
         color: 'white',
         stretch: 40,
         font: {
            resizeable: true,
            minSize: 12,
            maxSize: 18
         }
      },
   }
};

const lineOptions = {
   legend: { display: false },
   animation: false,
   maintainAspectRatio: false,
   title: {
      display: true,
      text: 'Production'
   },
   scales: {
      yAxes: [{
         stacked: true,
         ticks: { min: 0 },
      }],
   },
   elements: {
      line: { tension: 0 } // disables bezier curves
   },
   tooltips: {
      mode: 'index',
      intersect: false,
      position: 'nearest',
      itemSort: (a,b) => {
         const av = parseFloat(a.value);
         const bv = parseFloat(b.value);
         if (av > bv) return -1;
         if (av < bv) return 1;
         return 0;
      },
      filter: i => Math.round(i.value) > 0
   },
};

// Generic rounding to two decimal function
const rnd = (val, decimal = 100) => Math.round(val * decimal) / decimal;

// Generic return zero instead of NaN
// const nan = val => !isNaN(val) ? val : 0;

class Production extends Component {
   oilColumns = [
      { property: 'aggregate_name', header: 'Name', footer: 'Total' },
      { property: 'max_of_date', header: 'Current Prod Date' },
      { property: 'status', header: 'Lease Status' },
      { property: 'bopd_cur', header: 'Current BOPD', aggregate: 'sum', align: 'end',
         render: props => rnd(props.bopd_cur), footer: { aggregate: true },
      },
      { property: 'bopd_last', header: 'Previous BOPD', aggregate: 'sum', align: 'end',
         render: props => rnd(props.bopd_last), footer: { aggregate: true },
      },
      { property: 'bopd_diff', header: 'Short Term Delta BOPD(%)', aggregate: 'sum', align: 'end',
         render: props => <DeltaCell diff={rnd(props.bopd_diff)} percent={Math.round(props.bopd_percent)} />, footer: { aggregate: true },
      },
      { property: 'bopd_lt', header: 'Long Term BOPD', aggregate: 'sum', align: 'end',
         render: props => rnd(props.bopd_lt), footer: { aggregate: true },
      },
      { property: 'bopd_ltdiff', header: 'Long Term Delta BOPD(%)', aggregate: 'sum', align: 'end',
         render: props => <DeltaCell diff={rnd(props.bopd_ltdiff)} percent={Math.round(props.bopd_ltpercent)} />, footer: { aggregate: true },
      },
   ];
   gasColumns = [
      { property: 'aggregate_name', header: 'Name', footer: 'Total' },
      { property: 'max_of_date', header: 'Date' },
      { property: 'status', header: 'Status' },
      { property: 'mcfpd_cur', header: 'Current MCFPD', aggregate: 'sum', align: 'end',
         render: props => rnd(props.mcfpd_cur), footer: { aggregate: true },
      },
      { property: 'mcfpd_last', header: 'Previous MCFPD', aggregate: 'sum', align: 'end',
         render: props => rnd(props.mcfpd_last), footer: { aggregate: true },
      },
      { property: 'mcfpd_percent', header: 'Short Term MCFPD Delta', aggregate: 'sum', align: 'end',
         render: props => <DeltaCell diff={rnd(props.mcfpd_cur - props.mcfpd_last)} percent={Math.round(props.mcfpd_percent)} />
      },
      { property: 'mcfpd_lt', header: 'Long Term MCFPD', aggregate: 'sum', align: 'end',
         render: props => rnd(props.mcfpd_lt), footer: { aggregate: true },
      },
      { property: 'mcfpd_ltdiff', header: 'Long Term Delta MCFPD(%)', aggregate: 'sum', align: 'end',
         render: props => <DeltaCell diff={rnd(props.mcfpd_ltdiff)} percent={Math.round(props.mcfpd_ltpercent)} />, footer: { aggregate: true },
      },
   ];

   dailyCols = [
      { property: 'aggregate_name', header: 'Name' },
      { property: 'date', header: 'Date' },
      { property: 'oil', header: 'Oil' },
      { property: 'gas', header: 'Gas' },
      { property: 'pcomment', header: 'Pumper Comments'}
   ];

   topRef = React.createRef();

   constructor(props) {
      super(props);
      const token = sessionStorage.getItem(AUTH_TOKEN);
      const decodedToken = token ? decode(token) : null;

      this.handleSelectorChange = this.handleSelectorChange.bind(this);
      this.sorter = this.sorter.bind(this);
      this.checks = this.checks.bind(this);
      this.ptypeFilter = this.ptypeFilter.bind(this);
      this.dataFilter = this.dataFilter.bind(this);
      this.optionFilter = this.optionFilter.bind(this);
      this.optionLeaseFilter = this.optionLeaseFilter.bind(this);
      this.changeFilter = this.changeFilter.bind(this);
      this.handleChartClick = this.handleChartClick.bind(this);
      this.updateCharts = this.updateCharts.bind(this);
      this.handleTypeChange = this.handleTypeChange.bind(this);
      this.handleMonthlyChange = this.handleMonthlyChange.bind(this);
      this.handleCompanyChange = this.handleCompanyChange.bind(this);
      this.handleTopClick = this.handleTopClick.bind(this);

      this.state = {
         data: undefined,
         daily: undefined,
         workingData: undefined,
         workingDaily: undefined,
         selectedItem: '',
         selectedFilter: '',
         prodType: localStorage.getItem('prodType') || 'bopd',
         isMonthly: localStorage.getItem('isMonthly') === 'true' || false,
         isLoading: false,
         activeFilter: 'Area',
         pumperComments: true,
         companyId: decodedToken && decodedToken.admin &&
            decodedToken.company_id ? decodedToken.company_id*1 : undefined,
      }

      this.props.loadProduction(undefined, 'area', false);
   }

   componentDidUpdate(prevProps, prevState) {
      const { data, daily, isLoading } = this.props;
      if (prevProps.data !== data) {
         this.handleReadCSV(data);
      }
      if (prevProps.daily !== daily) {
         this.handleReadCSV2(daily);
      }
      if (prevProps.isLoading !== isLoading) {
         this.setState({ isLoading: this.props.isLoading });
      }
   }

   changeFilter(activeFilter) {
      const { isMonthly } = this.state;
      if (activeFilter !== this.state.activeFilter) {
         this.setState({ activeFilter, isLoading: true }, () => this.props.loadProduction(this.state.companyId, activeFilter.toLowerCase(), isMonthly));
      }
   }

   handleReadCSV(data) {
      const area = this.optionFilter(data, 'area', 'Area');
      const county = this.optionFilter(data, 'cty', 'County');
      const foreman = this.optionFilter(data, 'foreman', 'Foreman');
      const pumper = this.optionFilter(data, 'pumper', 'Pumper');
      const lease = this.optionFilter(data, 'lease', 'Lease');
      const lease2 = this.optionLeaseFilter(data, 'lease', 'Lease');
      this.setState({
         data, workingData: data,
         area, areaOptions: area,
         county, countyOptions: county,
         foreman, foremanOptions: foreman,
         pumper, pumperOptions: pumper,
         lease: lease2, leaseOptions: lease,
         selectedItem: '',
         selectedFilter: '',
      }, this.updateCharts);
   }

   handleReadCSV2(daily) {
      this.setState({
         daily, workingDaily: daily,
      }, this.updateCharts);
   }

   handleOnError(err, file, inputElem, reason) {
      console.log(err);
   }

   handleTypeChange() {
      const { prodType } = this.state;
      localStorage.setItem('prodType', prodType === 'bopd' ? 'mcfpd' : 'bopd');
      this.setState({ prodType: prodType === 'bopd' ? 'mcfpd' : 'bopd' }, this.updateCharts);
   }

   handleMonthlyChange() {
      const { isMonthly, activeFilter } = this.state;
      if (window.confirm('Switching Daily/Monthly data sets will reset your current search selections.  Choose Ok to switch.')) {
         localStorage.setItem('isMonthly', !isMonthly);
         this.setState({ isMonthly: !isMonthly }, () => this.props.loadProduction(this.state.companyId, activeFilter.toLowerCase(), !isMonthly));
      }
   }

// Pivot based on clicking the chart or table row
   handleChartClick(evt, arr) {
      if (evt.type === 'click' && ((arr && arr[0] && arr[0]._view) || evt.datum)) {
         const { selectedItem: currentSelectedItem, activeFilter, isMonthly } = this.state;
         const aggName = evt.datum ? evt.datum.aggregate_name : arr[0]._chart.config.data.fullLabels[arr[0]._index];
         if (activeFilter === 'Lease') {
            this.setState({
               selectedItem: aggName !== currentSelectedItem ? aggName : '',
               selectedFilter: activeFilter,
            }, this.updateCharts);
         } else {
            this.setState({
               selectedItem: aggName !== currentSelectedItem ? aggName : '',
               selectedFilter: activeFilter,
               activeFilter: 'Lease',
            }, () => this.props.loadProduction(this.state.companyId, 'lease', isMonthly));
         }
      }
   }

   handleSelectorChange(type, arr) {
      this.setState({ [type]: arr }, this.updateCharts);
   }

   handleCompanyChange(id) {
      const { isMonthly, activeFilter } = this.state;
      this.setState({ companyId: id }, () => this.props.loadProduction(id, activeFilter.toLowerCase(), isMonthly));
   }

   updateCharts() {
      const { data, daily, selectedItem, prodType } = this.state;
      let workingData = data;
      let workingDaily = daily;
      let colorArr = [];

      workingData = selectedItem === '' ?
         data.filter(this.checks) :
         data.filter(i => i.aggregate_name === selectedItem).filter(this.checks);
      let leases = workingData.map(i => i.aggregate_name);
      if (workingDaily && workingDaily.length > 0) workingDaily = workingDaily.filter(i => leases.indexOf(i['aggregate_name']) !== -1 );

      const bopdChart = this.dataFilter(workingData, `${prodType}_diff`, false, true, true, false);
      const bopdltChart = this.dataFilter(workingData, `${prodType}_ltdiff`, false, true, true, false);
      const pieChart = this.dataFilter(workingData, `${prodType}_cur`, false, false, false);
      // Make an associated array tying lease name to color.
      pieChart.fullLabels.forEach((val, idx) => {
         colorArr[val] = pieChart.datasets[0].backgroundColor[idx];
      })
      const lineChart = this.lineDataFilter(workingDaily, prodType, workingData, colorArr);
      this.setState({
         workingData,
         bopdChart,
         bopdltChart,
         pieChart,
         lineChart,
         workingDaily
      });
   }

// Sort data[a] and data[b] using @field
   sorter(a, b, field, numeric = false, asc = true) {
      const up = asc ? 1 : -1;
      if (numeric) {
         let pa = parseFloat(a[field]);
         let pb = parseFloat(b[field]);
         if (isNaN(pa)) pa = 0;
         if (isNaN(pb)) pb = 0;
         if (pa < pb) return -up;
         if (pa > pb) return up;
         return 0;
      } else {
         if (a[field] < b[field]) return -up;
         if (a[field] > b[field]) return up;
         return 0;
      }
   }

// Filter check using the selectioned options
   checks(item) {
      const { area, county, foreman, pumper, lease,
         areaOptions, countyOptions, foremanOptions, pumperOptions,
         leaseOptions
      } = this.state;
      if (area &&
         area.length !== areaOptions.length &&
         area.indexOf(item['aggregate_name']) === -1
      ) return false;
      if (county &&
         county.length !== countyOptions.length &&
         county.indexOf(item['aggregate_name']) === -1
      ) return false;
      if (foreman &&
         foreman.length !== foremanOptions.length &&
         foreman.indexOf(item['aggregate_name']) === -1
      ) return false;
      if (pumper &&
         pumper.length !== pumperOptions.length &&
         pumper.indexOf(item['aggregate_name']) === -1
      ) return false;
      if (lease &&
         lease.length !== leaseOptions.length &&
         lease.indexOf(item['aggregate_name']) === -1
      ) return false;

      return true;
   }

   // filter table rows based on ptype
   ptypeFilter(item) {
      const { prodType } = this.state;
      if (prodType === 'bopd') {
         if (item.ptype === 'Gas' || item.ptype === 'GAS') return false;
      } else {
         if (item.ptype === 'Oil' || item.ptype === 'OIL') return false;
      }
      return true;
   }

   dataFilter(workingData = null, field = null, numeric = false, asc = true, filterZero = false, colorize = true) {
      let newData;
      let tcolor = Tableau20;
      if (field && workingData) {
         while (tcolor.length < workingData.length) {
            tcolor = tcolor.concat(Tableau20);
         }
         let wData = [...workingData];
         wData.sort((a,b) => this.sorter(a,b, field, numeric, asc));
         newData = {
            // Trimming chart labels and keeping full length labels for searching
            labels: wData.filter(i => filterZero ? Math.round(i[field]) !== 0 : true).map(i => (i['aggregate_name'] && i['aggregate_name'].length > 20) ? i['aggregate_name'].substring(0,17) + '...' : i['aggregate_name']),
            fullLabels: wData.filter(i => filterZero ? Math.round(i[field]) !== 0 : true).map(i => i['aggregate_name']),
            datasets: [{
               data: wData.filter(i => filterZero ? Math.round(i[field]) !== 0 : true).map(i => rnd( i[field] )),
               backgroundColor: colorize ? tcolor : wData.filter(i => filterZero ? Math.round(i[field]) !== 0 : true).map(i => Math.round(i[field]) > 0 ? 'green' : 'red'),
            }],
         }
      }
      return newData;
   }

   lineDataFilter(workingDaily = null, prodType = 'bopd', workingData = null, colorArr = []) {
      let newChart;
      let tcolor = Tableau20;
      let dataType = prodType === 'bopd' ? 'oil' : 'gas';

      if (workingDaily) {
         // Pull the lease names from the aggregate data set so the order will be the same.
         const leases = workingData.map(i => i['aggregate_name']);
         let dates = new Set(workingDaily.map(i => i['date']));
         dates = Array.from(dates);
         while (leases && tcolor.length < leases.length) {
            tcolor = tcolor.concat(Tableau20);
         }

         newChart = {
            labels: [...dates],
            datasets: leases.map((i, index) => {
               // Need to double check if any datasets have date gaps.
               let wData = workingDaily.filter(x => x['aggregate_name'] === i);
               return ({
                  label: i,
                  backgroundColor: colorArr[i],
                  pointRadius: 0,
                  data: dates.map(d => {
                     let x = wData.find(e => e['date'] === d);
                     return x ? x[dataType] : 0;
                  }),
               })
            })
         }
         return newChart;
      }
   }

// Pull select options for @field from the @data
   optionFilter(data = null, field = null, filter = null) {
      const { activeFilter } = this.state;
      let result = null;
      let useField = activeFilter === filter ? 'aggregate_name' : field;
      if (field && data ) {
         result = new Set(data.map(i => i[useField]));
         result = Array.from(result);
         result = result.sort((a,b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
         });
      }
      return result;
   }

   optionLeaseFilter(data = null, field = null, filter = null) {
      const { activeFilter, selectedFilter, selectedItem } = this.state;
      let result = null;
      let useField = activeFilter === filter ? 'aggregate_name' : field;
      let useSelected = selectedFilter.toLowerCase();
      if (useSelected === 'county') useSelected = 'cty';
      if (field && data) {
         if (selectedFilter !== 'Lease' && activeFilter === 'Lease' && selectedItem !== '') {
            result = new Set(data.filter(i => i[useSelected] === selectedItem).map(i => i[useField]));
         } else {
            result = new Set(data.map(i => i[useField]));
         }
         result = Array.from(result);
         result = result.sort((a,b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
         });
      }
      return result;
   }

   handleTopClick() {
      this.topRef.current.scrollIntoView();
   }

   render() {
      const { workingData, area,
         areaOptions, county, countyOptions, foreman, foremanOptions, pumper,
         pumperOptions, lease, leaseOptions,
         bopdChart, bopdltChart, pieChart,
         lineChart, workingDaily, prodType, isMonthly,
         activeFilter, isLoading, pumperComments, companyId,
      } = this.state;
      const dataTableRows = workingData ? workingData.filter(this.ptypeFilter) : [];
      const dailyTableRows = workingDaily && workingDaily !== undefined ? (
      activeFilter === 'Lease' && pumperComments ?
      workingDaily.filter(this.ptypeFilter).filter(i => i.pcomment && i.pcomment.length > 1) :
      workingDaily.filter(this.ptypeFilter).map(i => ({...i, id: i.aggregate_name+i.date }))
      ).reverse() : [];

      let menu = (
         <Box direction='row'>
         {areaOptions ? <MediaQuery minWidth={1224}>
            <Selector
            checked={area}
            options={areaOptions}
            disabled={isLoading}
            handleSelectorChange={this.handleSelectorChange}
            changeFilter={this.changeFilter}
            activeFilter={activeFilter}
            label='Area'/></MediaQuery> : null}
         {countyOptions ? <Selector
            checked={county}
            options={countyOptions}
            disabled={isLoading}
            handleSelectorChange={this.handleSelectorChange}
            changeFilter={this.changeFilter}
            activeFilter={activeFilter}
            label='County'/> : null}
         {foremanOptions ? <Selector
            checked={foreman}
            options={foremanOptions}
            disabled={isLoading}
            handleSelectorChange={this.handleSelectorChange}
            changeFilter={this.changeFilter}
            activeFilter={activeFilter}
            label='Foreman'/> : null}
         {pumperOptions ? <Selector
            checked={pumper}
            options={pumperOptions}
            disabled={isLoading}
            handleSelectorChange={this.handleSelectorChange}
            changeFilter={this.changeFilter}
            activeFilter={activeFilter}
            label='Pumper'/> : null}
         {leaseOptions ? <Selector
            checked={lease}
            options={leaseOptions}
            disabled={isLoading}
            handleSelectorChange={this.handleSelectorChange}
            changeFilter={this.changeFilter}
            activeFilter={activeFilter}
            label='Lease'/> : null}
         </Box>
      );

      const title = (isMonthly ? 'Monthly ' : 'Daily ') +
         (prodType === 'bopd' ? 'Oil ' : 'Gas ');

      return (
         <Box ref={this.topRef}>
         <MediaQuery maxWidth={1224}>
            <Banner title={title} isLoading={isLoading} companyId={companyId}
               markdown={InfoHelp1}
               handleCompanyChange={this.handleCompanyChange}
            />
         </MediaQuery>
         <MediaQuery minWidth={1224}>
            <Banner title={title + 'Production Dashboard'} isLoading={isLoading}
               companyId={companyId}
               markdown={InfoHelp1}
               handleCompanyChange={this.handleCompanyChange}
            />
         </MediaQuery>
         <MediaQuery minWidth={1224}>
         <Grid
            columns={ [ '18%', '18%', '60%' ] }
            rows={[
               'xxsmall', //0,0   1,0   2,0
               'medium',  //0,1   1,1   2,1
               'medium',  //0,2   1,2   2,2
               'medium'   //0,3   1,3   2,3
            ]}
            gap='small'
            areas={[
               { name: 'MENU', start: [0,0], end: [2,0] },
               { name: 'BOPD', start: [0,1], end: [0,1] },
               { name: 'BOPDLT', start: [1,1], end: [1,1] },
               { name: 'PIE', start: [0,2], end: [1,2] },
               { name: 'TABLE', start: [2,1], end: [2,1] },
               { name: 'LINE', start: [2,2], end: [2,2] },
               { name: 'COMMENTS', start: [2,3], end: [2,3] },
            ]}
         >
            <Box gridArea='MENU' direction='row' gap='medium' margin={{left: 'medium'}}>
               <ToggleSwitch text1='Oil' text2='Gas'
                  disabled={isLoading}
                  checked={prodType !== 'bopd'} onChange={this.handleTypeChange}
               />
               <Box border='right' />
               <ToggleSwitch text1='Daily' text2='Monthly'
                  disabled={isLoading}
                  checked={isMonthly} onChange={this.handleMonthlyChange}
               />
               {menu}
            </Box>
            <Box key='BOPD' gridArea='BOPD' overflow='auto' style={{border: 'solid 1px'}}>
               {bopdChart ?
                  <HorizontalBar
                     id='BOPD'
                     data={bopdChart}
                     redraw={true}
                     height={bopdChart.datasets[0].data.length*15}
                     options={{...horizontalOptions,
                        title: {
                           display: true,
                           fontSize: 16,
                           text: `Current ${prodType.toUpperCase()} Delta`
                        },
                        onClick: this.handleChartClick
                     }}
                  />
               : null}
            </Box>
            <Box key='BOPDLT' gridArea='BOPDLT' overflow='auto' style={{border: 'solid 1px'}}>
               {bopdltChart ?
                     <HorizontalBar
                        id='BOPDLT'
                        data={bopdltChart}
                        redraw={true}
                        height={bopdltChart.datasets[0].data.length*15}
                        options={{...horizontalOptions,
                           title: {
                              display: true,
                              fontSize: 16,
                              text: `Long Term ${prodType.toUpperCase()} Delta`
                           },
                           onClick: this.handleChartClick
                        }}
                     />
               : null}
            </Box>
            <Box key='TABLE' gridArea='TABLE' style={{border: 'solid 1px'}}>
               {dataTableRows.length > 0 ?
                  <Box margin={{top: 'small'}} pad='xsmall' gap='small'>
                  <Box direction='row' gap='small' align='center' pad='small'>
                     <Box flex={true} align='center'>
                        <Text weight='bold'>{`${isMonthly ? 'Monthly' : 'Daily'} Production Metrics`}</Text>
                     </Box>
                  </Box>
                  <DataTable
                     data={dataTableRows} sortable={true} size='small'
                     pad={{ header: 'xsmall', body: 'none', footer: 'none'}}
                     columns={prodType === 'bopd' ? this.oilColumns : this.gasColumns}
                     background={{
                        header: { color: '#499894', dark: true },
                        footer: 'lightgrey',
                     }}
                     onClickRow={this.handleChartClick}
                  />
                  </Box>
               : null}
            </Box>
            <Box key='PIE' gridArea='PIE' style={{border: 'solid 1px'}}>
               {pieChart ?
                  <OutlabeledPie
                     data={pieChart}
                     options={{...pieOptions,
                        title: {
                           display: true,
                           fontSize: 16,
                           text: `Current Production Rate`
                        },
                        onClick: this.handleChartClick
                     }}
                  />
               : null}
            </Box>
            <Box key='LINE' gridArea='LINE' style={{border: 'solid 1px'}}>
               {!isLoading && lineChart ?
                  <Line
                     data={lineChart}
                     options={{
                        ...lineOptions,
                     }}
                  />
               : null}
            </Box>
            <Box key='COMMENTS' gridArea='COMMENTS'>
               {dailyTableRows.length > 0 ?
                  <Box pad='xsmall'>
                  <Box margin={{vertical: 'small'}} pad='small' direction='row' gap='small' align='center'>
                     {activeFilter === 'Lease' ?
                     <ToggleSwitch text1='All Production' text2='Pumper Comments'
                        checked={pumperComments}
                        onChange={() => this.setState({pumperComments: !pumperComments})}
                     /> : null}
                     <Box flex={true} />
                  </Box>
                  <DataTable
                     data={dailyTableRows} sortable={true} size='large' primaryKey='id'
                     pad={{ header: 'xsmall', body: 'none', footer: 'none'}}
                     columns={this.dailyCols}
                     background={{ header: { color: '#499894', dark: true } }}
                  />
                  </Box>
               : null}
            </Box>
         </Grid>
         </MediaQuery>
         <MediaQuery maxWidth={1224} orientation={'portrait'}>
            <Box pad='small'>
            Please rotate your device for Landscape viewing.
            </Box>
         </MediaQuery>
         <MediaQuery maxWidth={1224} orientation={'landscape'}>
         <Fab
            icon={<LinkTop color='white'/>}
            onClick={() => this.handleTopClick()}
            event='click'
            mainButtonStyles = {{opacity: 0.5}}
         />
         <Grid
            columns={[ '100%' ]}
            rows={[
               'xsmall', // 0,0
               'medium',  // 0,1
               'medium',  // 0,2
               'medium',  // 0,3
            ]}
            gap='small'
            areas={[
               { name: 'MENU', start: [0,0], end: [0,0] },
               { name: 'TABLE', start: [0,1], end: [0,1] },
               { name: 'LINE', start: [0,2], end: [0,2] },
               { name: 'COMMENTS', start: [0,3], end: [0,3] },
            ]}
         >
         <Box gridArea='MENU' gap='medium' margin={{left: 'medium'}}>
            <ToggleSwitch text1='Oil' text2='Gas'
               disabled={isLoading}
               checked={prodType !== 'bopd'} onChange={this.handleTypeChange}
            />
            {menu}
         </Box>
         <Box key='TABLE' gridArea='TABLE' style={{border: 'solid 1px'}}>
            {dataTableRows.length > 0 ?
               <Box margin={{top: 'small'}} pad='xsmall' gap='small'>
               <Box direction='row' gap='small' align='center' pad='small'>
                  <Box flex={true} align='center'>
                     <Text weight='bold'>{`${isMonthly ? 'Monthly' : 'Daily'} Production Metrics`}</Text>
                  </Box>
               </Box>
               <DataTable
                  data={dataTableRows} sortable={true} size='small'
                  pad={{ header: 'xsmall', body: 'none', footer: 'none'}}
                  columns={prodType === 'bopd' ?
                     this.oilColumns.filter(i => i.property !== 'status') :
                     this.gasColumns.filter(i => i.property !== 'status')
                  }
                  background={{
                     header: { color: '#499894', dark: true },
                     footer: 'lightgrey',
                  }}
                  onClickRow={this.handleChartClick}
               />
               </Box>
            : null}
         </Box>
         <Box key='LINE' gridArea='LINE' style={{border: 'solid 1px'}}>
            {!isLoading && lineChart ?
               <Line
                  data={lineChart}
                  options={{
                     ...lineOptions,
                  }}
               />
            : null}
         </Box>
         <Box key='COMMENTS' gridArea='COMMENTS'>
            {dailyTableRows.length > 0 ?
               <Box pad='xsmall'>
               <Box margin={{vertical: 'small'}} pad='small' direction='row' gap='small' align='center'>
                  {activeFilter === 'Lease' ?
                  <ToggleSwitch text1='All Production' text2='Pumper Comments'
                     checked={pumperComments}
                     onChange={() => this.setState({pumperComments: !pumperComments})}
                  /> : null}
                  <Box flex={true} />
               </Box>
               <DataTable
                  data={dailyTableRows} sortable={true} size='large' primaryKey='id'
                  pad={{ header: 'xsmall', body: 'none', footer: 'none'}}
                  columns={this.dailyCols}
                  background={{ header: { color: '#499894', dark: true } }}
               />
               </Box>
            : null}
         </Box>
         </Grid>
         </MediaQuery>
         </Box>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      data: state.production.data,
      daily: state.production.daily,
      isLoading: state.production.isLoading,
      prodType: state.production.prodType,
      bopdChart: state.production.bopdChart,
      bopdltChart: state.production.bopdltChart,
      pieChart: state.production.pieChart,
      lineChart: state.production.lineChart,
   }
}

const mapDispatchToProps = { loadProduction }

export default connect(mapStateToProps, mapDispatchToProps)(Production);
