import React, { Component } from 'react';
import { ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';

const LOGOUT_MUTATION = gql`
   mutation LogoutMutation {
      logout {
         token
         message
      }
   }
`;

/**
 * Logout for the application.
 */
export default class Logout extends Component {
   constructor(props, context) {
      super(props, context);
      sessionStorage.clear();
   }

   render() {
      return (
         <ApolloConsumer>
            {client => {
               client.resetStore();
               client.mutate({
                  mutation: LOGOUT_MUTATION
               });
               return <Redirect to='/login' />;
            }}
         </ApolloConsumer>
      );
   }
}
