import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { Provider as ReduxProvider } from 'react-redux'
import decode from 'jwt-decode'

import store from './api/configureStore'

import { Grommet } from 'grommet'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import NotificationPop from './shared/NotificationPop'

import Production from './page/Production'
import Lifting from './page/Lifting'
import Login from './page/Login'
import Logout from './page/Logout'
import Forgot from './page/Forgot'
import Signup from './page/Signup'
import Reset from './page/Reset'
import AdminPages from './page/Admin'
import Chart from './page/Chart'


import { AUTH_TOKEN } from './shared/constants'

import { initClient } from './api/apollo'

const httpLink = createHttpLink({
   uri: '/graphql'
})
const errorLink = onError(({graphQLErrors, networkError}) => {
   if (graphQLErrors) {
      graphQLErrors.map(({ extensions }) => {
         if (extensions && extensions.code === `UNAUTHENTICATED`) {
            window.location='/logout';
         }
         return extensions;
      });
   } else if (networkError) {
      switch (networkError.statusCode) {
         case 302:
            if (!window.location.pathname.includes('reset')) {
               window.location='/logout';
            }
            break;
         case 401:
            window.location='/logout';
            break;
         default:
      }
   }
})

// Checking sessionStorage JWT for admin status.
const token = sessionStorage.getItem(AUTH_TOKEN)
const decodedToken = token ? decode(token) : null

const client = new ApolloClient({
   link: ApolloLink.from([
      errorLink,
      httpLink
   ]),
   cache: new InMemoryCache(),
})

store.dispatch(initClient(client))

const myTheme = {
   global: {
      colors: {
         brand: '#006666',
         focus: '#f0c954',
         'accent-1': '#9fd4c9',
         'accent-2': '#d5d848',
      },
      font: {
         family: 'Helvetica',
      }
   },
   tab: {
      border: {
         color: {
            light: 'focus'
         }
      }
   }
}

window.onbeforeunload = function(e) {
   // Don't warn when moving away from the login page.
   if (window.location.pathname === '/login') return;
   // The specific confirmation window is handled by the browser.
   return 'Are you sure you want to close or reload?';
}

ReactDOM.render(
   <ReduxProvider store={store}>
      <ApolloProvider client={client}>
         <Grommet full theme={myTheme}>
            <Router>
               <NotificationPop />
               <Switch>
                  <Route exact path='/' render={() => <Redirect to='/login'/>}/>
                  <Route path='/production' component={Production}/>
                  <Route path='/lifting' component={Lifting}/>
                  <Route path='/login' component={Login}/>
                  <Route path='/logout' component={Logout}/>
                  <Route path='/signup' component={Signup}/>
                  <Route path='/forgot' component={Forgot}/>
                  <Route path='/reset' component={Reset}/>
                  <Route path='/chart' component={Chart}/>
                  {decodedToken && decodedToken.admin ?
                     <Route path='/admin' component={AdminPages}/> :
                     null
                  }
                  <Route render={() => <Redirect to='/login'/>}/>
               </Switch>
            </Router>
         </Grommet>
      </ApolloProvider>
   </ReduxProvider>,
   document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
