import { applyMiddleware, createStore, combineReducers } from 'redux';
import { graphqlReducer, addGraphQLEndpoints } from 'iguazu-graphql';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// reducers to combine
import notification from './notification';
import apollo from './apollo';
import production from '../page/Production/api';
import admin from '../page/Admin/api';
import lifting from '../page/Lifting/api';

const rootReducer = combineReducers({
   iguazuGraphQL: graphqlReducer,
   notification, apollo, production, admin, lifting,
});

// A couple additional development enhancements
// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import loggerMiddleware from './middleware/logger'
// import rootReducer from './reducers';  // If using a single directory


export function configureStore(preloadedState) {
   const middlewares = [thunkMiddleware];
   // Can conditional add more middlewares
   const middlewareEnhancer = applyMiddleware(...middlewares);

   const enhancers = [middlewareEnhancer];
   const composedEnhancers = composeWithDevTools(...enhancers);

   const store = createStore(rootReducer, preloadedState, composedEnhancers);

   // Hot replacer for reducer modifications.  Not currently used, but could be 
   // if (process.env.NODE_ENV !== 'production' && module.hot) {
   //    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
   // }

   return store;
}

// Go ahead an initialize the shared store.
const store = configureStore();
addGraphQLEndpoints([
   {
      name: 'pro',
      fetch: () => ({ url: '/graphql' }),
   },
]);

export default store;
// Add to index.js ????
// if (process.env.NODE_ENV !== 'production' && module.hot) {
//   module.hot.accept('./components/App', renderApp)
// }
