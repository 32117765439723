// Actions
export const SEND_NOTIFICATION = "notification/SEND_NOTIFICATION";
export const CLEAR_NOTIFICATION = "notification/CLEAR_NOTIFICATION";


//Reducer
const initialState = {
   isEmpty: true,
   timestamp: new Date(),
   size: 'medium',
   status : 'warning',
   state: 'Error',
   message : `Could not fetch some data.`,
};

export default function reducer(state = initialState, action = {}) {
   const { type, payload } = action;
   switch (type) {
      case SEND_NOTIFICATION:
         return {
            ...state,
            isEmpty: false,
            timestamp: new Date(),
            status: payload.status !== null ? payload.status : 'warning',
            state: payload.note,
            message: payload.message !== null ? payload.message :
               `Could not fetch some data.  Try manually refreshing the page to collect fresh data.  If the problem persists, please let us know.`,
         }
      case CLEAR_NOTIFICATION:
         return {
            ...state,
            isEmpty: true,
         }
      default: return state;
   }
}


// Action creators
export const sendNotification = (note, status = null, message = null) => ({
   type: SEND_NOTIFICATION,
   payload: {
      note,
      status,
      message,
   }
});

export const clearNotification = () => ({
   type: CLEAR_NOTIFICATION,
});
