import React from 'react';

import { Box, Button, Select, Text } from 'grommet';
import { Trash } from 'grommet-icons';
import { useMediaQuery } from 'react-responsive';

import NewSelector from '../../../shared/NewSelector';
import { SubSelector } from '../../../shared/SubSelector';
import FIXED from '../colorConstants';
import QUERY from '../api/MenuOptions';


const Menu = ({
   companyId,
   activeFilter, changeFilter, handleSelectorChange,
   area, county, foreman, pumper, lease,
   selectedExpType, handleExpTypeChange,
}) => {

   const isMobile = useMediaQuery({
      query: '(max-device-width: 1224px)'
   });

   const menuList = [
      { label: 'Area', checked: area },
      { label: 'County', checked: county },
      { label: 'Foreman', checked: foreman },
      { label: 'Pumper', checked: pumper },
      { label: 'Lease', checked: lease }
   ];

   if (isMobile) menuList.shift();

   const menu = menuList.map((item) =>
      item.label === activeFilter ?
      <NewSelector
         key={item.label}
         companyId={companyId}
         label={item.label}
         checked={item.checked}
         query={QUERY}
         handleSelectorChange={handleSelectorChange}
      /> :
      <SubSelector
         key={item.label}
         label={item.label}
         changeFilter={changeFilter}
      />
   );

   const expenseSelector = <Box direction='row' align='center'>
      <Text>Expense Type:</Text>
      <Select
         value={selectedExpType}
         placeholder={selectedExpType}
         options={FIXED.extra}
         children={(option) => <Text>{option.label}</Text>}
         onChange={({option}) => handleExpTypeChange(option.label) }
      />
      <Button icon={<Trash/>}
         fill={false} margin='small' plain={false}
         disabled={selectedExpType === 'All'}
         onClick={() => handleExpTypeChange('All') }
      />
   </Box>

   return (
   <Box pad={{'horizontal': 'small'}} direction='row' flex={true} justify='between'>
      <Box direction='row' align='center'>
         {menu}
      </Box>
      {!isMobile ? expenseSelector : null}
   </Box>
)};

export default Menu;
