import React from 'react'
import { useTable, useSortBy } from 'react-table'
import { Table } from 'grommet'
import { ExpColumns2 as columns } from '../LiftingColumns'
import { GrommetTableHeader, GrommetTablePlainBody, GrommetTableFooter } from '../../../shared/GrommetTablePieces'

// Functional Component to allow using React Hooks needed for the react-table framework
export function ExpenseTable({ data }) {

   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
   } = useTable({
      columns,
      data,
   },
      useSortBy,
   );

   // Render the JSX for the table
   // Using Grommet Table mechanics for look and feel
   return (
      <Table {...getTableProps()}>
         <GrommetTableHeader headerGroups={headerGroups} />
         <GrommetTablePlainBody getTableBodyProps={getTableBodyProps} rows={rows} prepareRow={prepareRow} />
         <GrommetTableFooter footerGroups={footerGroups} />
      </Table>
   );
}
