import React from 'react';
import { Box, Meter, Text } from 'grommet';
import { CaretDown, CaretNext } from 'grommet-icons';
import moment from 'moment';
import Fixed from './colorConstants';

// Generic rounding to two decimal function
const rnd = (val, decimal = 100, format = true, dollar = false) => {
   if (isNaN(val)) return '';
   let r = Math.round(val * decimal) / decimal;
   const isNeg = r < 0;
   if (format) r = (isNeg ? -r : r).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
   if (format && dollar) r = `$${r}`;
   if (format && isNeg) r = `(${r})`;
   return r;
}

const txtColumn = (field, title, foot = false, size = 'small') =>
{ return {
   property: field,
   footer: foot ? foot : undefined,
   header: <Text size='small' weight='bold'>{title}</Text>,
   render: props => <Text size={size}>{props[field]}</Text>
}};

const stdColumn = (field, title, decimal = 1, agg = true, align = 'end', dollar = true) =>
{ return {
   property: field,
   align: align,
   aggregate: agg ? 'sum' : undefined,
   footer: agg ? { aggregate: true } : undefined,
   header: <Text size='small' weight='bold'>{title}</Text>,
   render: props => <Text size='small'>{rnd(props[field], decimal, true, dollar)}</Text>
}};

const barColumn = (maxes = undefined, field, title, colorTag = 'ad_tax', dollar = false, decimal = undefined) =>
{ return {
   property: field,
   header: <Text size='small' weight='bold'>{title}</Text>,
   render: props => props[field] ?
   <Box>
      <Text size='small'>{rnd(props[field], decimal, dollar, dollar)}</Text>
      <Meter max={maxes !== undefined ? maxes[field] : 0} thickness='small' size='small'
         values={[{ value: rnd(props[field], undefined, false),
            color: Fixed.getColorFromDb(colorTag)
         }]}
      />
   </Box>
   : null
}};

// Specifically for the Net / BOE column to change color based on company totals
const netBoeColumn = (maxes = undefined, field, title, totals = undefined, dollar = true, decimal = undefined) =>
{ return {
   property: field,
   header: <Text size='small' weight='bold'>{title}</Text>,
   render: props => props[field] ?
   <Box>
      <Text size='small'>{rnd(props[field], decimal, dollar, dollar)}</Text>
      <Meter max={maxes !== undefined ? maxes[field] : 0} thickness='small' size='small'
         values={[{ value: rnd(props[field], undefined, false),
            color: props[field] >= totals.above ? 'red' :
               props[field] < totals.below ? 'green' :
               'orange'
         }]}
      />
   </Box>
   : null
}};

export const SummaryColumns = (maxes = undefined, totals = undefined, isMobile = false) => {

   return isMobile ? [
      txtColumn('name', 'Name', 'Total'),
      netBoeColumn(maxes, 'total_exp_per_mon_per_boe', 'Net /BOE', totals),
      stdColumn('total_exp_per_mon_per_mcf', 'Net /MCF', 100, false, 'start'),
      stdColumn('act_pwells', 'Act Wells', undefined, true, 'start', false),
      stdColumn('total_exp_per_well', 'LOE /Act Well /Mon', undefined, false, 'start'),
      stdColumn('bopd_per_allwells', 'BOEPD /All Wells', 100, false, 'start', false),
      stdColumn('bopd', 'BOPD', 10, undefined, undefined, false),
      stdColumn('mcfpd', 'MCFPD', undefined, undefined, undefined, false),
      stdColumn('bwpd', 'BWPD', undefined, undefined, undefined, false),
      stdColumn('total_exp', 'Total'),
   ] : [
      txtColumn('name', 'Name', 'Total'),
      netBoeColumn(maxes, 'total_exp_per_mon_per_boe', 'Net /BOE', totals),
      stdColumn('total_exp_per_mon_per_mcf', 'Net /MCF', 100, false, 'start'),
      stdColumn('act_pwells', 'Act Wells', undefined, true, 'start', false),
      stdColumn('total_exp_per_well', 'LOE /Act Well /Mon', undefined, false, 'start'),
      stdColumn('bopd_per_allwells', 'BOEPD /All Wells', 100, false, 'start', false),
      stdColumn('bopd', 'BOPD', 10, undefined, undefined, false),
      stdColumn('mcfpd', 'MCFPD', undefined, undefined, undefined, false),
      stdColumn('bwpd', 'BWPD', undefined, undefined, undefined, false),
      stdColumn('ad_tax', 'Taxes'),
      stdColumn('propane', 'NonElec'),
      stdColumn('electric', 'Electric'),
      stdColumn('chemicals', 'Chemicals'),
      stdColumn('failure', 'Failure'),
      stdColumn('swd_trucking', 'SWD Trucking'),
      stdColumn('swd_fees', 'SWD Fees'),
      stdColumn('equip_rm', 'Equip R&M'),
      stdColumn('other_exp', 'Other'),
      stdColumn('fixed', 'Fixed'),
      stdColumn('total_exp', 'Total'),
      stdColumn('wi_profit', 'WI Profit IDX'),
   ];
};

export const CostColumns = (maxes = undefined, isMobile = false) => {
   return isMobile ? [
      txtColumn('name', 'Name', 'Total'),
      barColumn(maxes, 'tax_lc', 'Taxes / Net BOE', 'ad_tax'),
      barColumn(maxes, 'elecprop_per_bf', 'Utility / BF', 'electric'),
      barColumn(maxes, 'swd_total_per_bw', 'SWD / BW', 'swd_trucking'),
      barColumn(maxes, 'fixed_lc', 'Fixed / Net BOE', 'fixed'),
   ] : [
      txtColumn('name', 'Name', 'Total'),
      stdColumn('bopd', 'BOPD', 10, undefined, undefined, false),
      stdColumn('mcfpd', 'MCFPD', undefined, undefined, undefined, false),
      stdColumn('bwpd', 'BWPD', undefined, undefined, undefined, false),
      stdColumn('ad_tax', 'Taxes'),
      barColumn(maxes, 'tax_lc', 'Taxes / Net BOE', 'ad_tax'),
      stdColumn('utility', 'Utility'),
      barColumn(maxes, 'elecprop_per_bf', 'Utility / BF', 'electric'),
      stdColumn('swd_total', 'SWD Truck + Fees'),
      barColumn(maxes, 'swd_total_per_bw', 'SWD / BW', 'swd_trucking'),
      stdColumn('fixed', 'Fixed'),
      barColumn(maxes, 'fixed_lc', 'Fixed / Net BOE', 'fixed'),
   ];
};

export const ChemColumns = (maxes = undefined, isMobile = false) => {
   return isMobile ? [
      txtColumn('name', 'Name', 'Total'),
      barColumn(maxes, 'chemfail_ratio', 'CBS/Fail Ratio', 'failure'),
      barColumn(maxes, 'chem_cbs_fail_per_pwell', 'CBS+Fail / Well', 'failure', true, 1),
      barColumn(maxes, 'chem_cbs_per_bf', 'CBS / BF', 'chemicals'),
      barColumn(maxes, 'chem_emul_per_boe', 'Demul / BO', 'chemicals'),
      barColumn(maxes, 'chem_par_per_boe', 'Paraffin / BO', 'chemicals'),
   ] : [
      txtColumn('name', 'Name', 'Total'),
      stdColumn('bopd', 'BOPD', 10, undefined, undefined, false),
      stdColumn('mcfpd', 'MCFPD', undefined, undefined, undefined, false),
      stdColumn('bwpd', 'BWPD', undefined, undefined, undefined, false),
      stdColumn('chemicals', 'Chemicals'),
      stdColumn('failure', 'Failure'),
      barColumn(maxes, 'chemfail_ratio', 'CBS/Fail Ratio', 'failure'),
      stdColumn('chem_cbs_fail', 'Combined CBS+Fail'),
      barColumn(maxes, 'chem_cbs_fail_per_pwell', 'CBS+Fail / Well', 'failure', true, 1),
      stdColumn('chem_cbs', 'CBS'),
      barColumn(maxes, 'chem_cbs_per_bf', 'CBS / BF', 'chemicals'),
      stdColumn('chem_emul', 'Demul'),
      barColumn(maxes, 'chem_emul_per_boe', 'Demul / BO', 'chemicals'),
      stdColumn('chem_par', 'Paraffin'),
      barColumn(maxes, 'chem_par_per_boe', 'Paraffin / BO', 'chemicals'),
   ];
};

export const CrosstabColumns = (start = undefined) => {
   let m = moment(start).startOf('month');
   const d = (num) => moment(m).subtract(num, 'month').format('YYYY-MM');

   return [
   { property: 'exp_cat_name', footer: 'Total',
      header: <Text size='small'>Category</Text>,
      render: props => <Box width={{'min': '200px'}}><Text size='xsmall'>{props.exp_cat_name}</Text></Box> },
   stdColumn('m12', d(11)),
   stdColumn('m11', d(10)),
   stdColumn('m10', d(9)),
   stdColumn('m9', d(8)),
   stdColumn('m8', d(7)),
   stdColumn('m7', d(6)),
   stdColumn('m6', d(5)),
   stdColumn('m5', d(4)),
   stdColumn('m4', d(3)),
   stdColumn('m3', d(2)),
   stdColumn('m2', d(1)),
   stdColumn('m1', d(0)),
   { property: 'lineSum', align: 'end', aggregate: 'sum', footer: { aggregate: true },
      header: <Text size='small' weight='bold'>Total</Text>,
      render: props => <Text size='small'>{rnd(
         props.m12 + props.m11 + props.m10 + props.m9 + props.m8 + props.m7 +
         props.m6 + props.m5 + props.m4 + props.m3 + props.m2 + props.m1
         , 1)}</Text>
   },
]};

export const ExpColumns = [
   txtColumn('exp_cat_name', 'Category', 'Total'),
   txtColumn('etype', 'Type'),
   txtColumn('date', 'Date'),
   stdColumn('exp', 'Expense', 1, true, 'start'),
   txtColumn('desc1', 'Desc 1', false, 'xsmall'),
   txtColumn('desc2', 'Desc 2', false, 'xsmall'),
];

// stdColumn2 is for the react-table functional components
const stdColumn2 = (field, title, decimal = 1, agg = true, align = 'end', dollar = true) => {
   return {
      accessor: field,
      Header: <Box align={align}><Text size='small' weight='bold' color='#F8F8F8'>{title}</Text></Box>,
      Cell: ({ cell: { value } }) => <Box align={align}><Text size='small'>{
         rnd(value, decimal, true, dollar)
      }</Text></Box>,
      Footer: ({ data }) => agg ? <Box align={align}><Text size='small' weight='bold'>{
         rnd(data.reduce((a,b) => a + b[field], 0), decimal, true, dollar)
      }</Text></Box> : null,
   }
}

// CrosstabColumns2 is for the react-table functional components
export const CrosstabColumns2 = (start = undefined, total_exp = undefined) => {
   let m = moment(start).startOf('month');
   const d = (num) => moment(m).subtract(num, 'month').format('YYYY-MM');

   return [
      {
         Header: () => null, // no header
         id: 'expander', // make sure it has an ID
         Cell: ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
               {row.isExpanded ? <CaretDown /> : <CaretNext />}
            </span>
         ),
      },
      {
         accessor: 'exp_cat_name',
         Header: <Text size='small' weight='bold' color='#F8F8F8' textAlign='start'>Category</Text>,
         Cell: ({ cell: { value } }) => <Text size='small'>{value}</Text>,
         Footer: <Text size='small' weight='bold'>Total</Text>,
      },
      stdColumn2('m12', d(11)),
      stdColumn2('m11', d(10)),
      stdColumn2('m10', d(9)),
      stdColumn2('m9', d(8)),
      stdColumn2('m8', d(7)),
      stdColumn2('m7', d(6)),
      stdColumn2('m6', d(5)),
      stdColumn2('m5', d(4)),
      stdColumn2('m4', d(3)),
      stdColumn2('m3', d(2)),
      stdColumn2('m2', d(1)),
      stdColumn2('m1', d(0)),
      {
         accessor: 'total',
         Header: <Box align='end'><Text size='small' weight='bold' color='#F8F8F8'>Total</Text></Box>,
         Cell: ({ cell: { row: { values } } }) => {
            const rowTotal = values.m12 + values.m11 + values.m10 + values.m9 +
            values.m8 + values.m7 + values.m6 + values.m5 +
            values.m4 + values.m3 + values.m2 + values.m1;
            return <Box align='end'><Text size='small'>{ rnd(rowTotal, 1, true, true) }</Text></Box>
         },
         Footer: <Box align='end'><Text size='small' weight='bold'>{rnd(total_exp, 1, true, true)}</Text></Box>
      },
   ]
}

// txtColumn2 is for the react-table functional components
const txtColumn2 = (field, title, foot = false, size = 'small') => {
   return {
      accessor: field,
      Header: <Text size='small' weight='bold' color='#F8F8F8'>{title}</Text>,
      Cell: ({ cell: { value } }) => <Text size={size}>{value}</Text>,
      Footer: foot ? <Text size='small' weight='bold'>{foot}</Text> : '',
   }
};

// ExpColumns2 is for the react-table functional components
export const ExpColumns2 = [
   // By embedding this table in the other row the category and type are redundant.
   // txtColumn2('exp_cat_name', 'Category', 'Total'),
   // txtColumn2('etype', 'Type'),
   txtColumn2('date', 'Date', 'Total'),
   stdColumn2('exp', 'Expense', 1, true, 'start'),
   txtColumn2('desc1', 'Desc 1', false, 'xsmall'),
   txtColumn2('desc2', 'Desc 2', false, 'small'),
];
