export const TABLEAU20 = ['#4E79A7', '#A0CBE8', '#F28E2B', '#FFBE7D', '#59A14F', '#8CD17D', '#B6992D', '#F1CE63', '#499894', '#86BCB6', '#E15759', '#FF9D9A', '#79706E', '#BAB0AC', '#D37295', '#FABFD2', '#B07AA1', '#D4A6C8', '#9D7660', '#D7B5A6'];

export const LIFTING_COSTS = {
   'swd_trucking': '#4E79A7',
   'swd_fees': '#A0CBE8',
   'chemicals': '#F28E2B',
   'ad_tax': '#FFBE7D',
   'electric': '#59A14F',
   'fixed': '#499894',
   'failure': '#E15759',
   'equip_rm': '#B6992D',
   'other_exp': '#F1CE63',
   'propane': '#8CD17D',
   // 'next2': '#86BCB6',
};

export const TABLE_BACKGROUND = {
   'header': '#499894',
   'odd': '#F8F8F8',
   'even': '#EDEDED',
   'footer': '#D3D3D3',
};

export const CHEM_FAIL_LABEL = 'Chemicals & Failures';
export const UTILITY_LABEL = 'Utilities';
export const SWD_LABEL = 'SWD Trucking & Fees';

export default class Fixed {
   static order = [
      {index: 0, db: 'ad_tax', label: 'Taxes', color: '#FFBE7D'},
      {index: 1, db: 'propane', label: 'NonElec', color: '#8CD17D'},
      {index: 2, db: 'electric', label: 'Electric', color: '#59A14F'},
      {index: 3, db: 'chemicals', label: 'Chemicals', color: '#F28E2B'},
      {index: 4, db: 'failure', label: 'Failures', color: '#E15759'},
      {index: 5, db: 'swd_trucking', label: 'SWD Truck', color: '#4E79A7'},
      {index: 6, db: 'swd_fees', label: 'SWD Fee', color: '#A0CBE8'},
      {index: 7, db: 'equip_rm', label: 'Equip R&M', color: '#B6992D'},
      {index: 8, db: 'other_exp', label: 'Other', color: '#F1CE63'},
      {index: 9, db: 'fixed', label: 'Fixed', color: '#499894'},
   ];

   static extra = [
      {index: 11, db: 'all', label: 'All'},
      {index: 0, db: 'ad_tax', label: 'Taxes', color: '#FFBE7D'},
      {index: 10, db: 'chemfail', label: CHEM_FAIL_LABEL},
      {index: 12, db: 'utility', label: UTILITY_LABEL},
      {index: 13, db: 'swd', label: SWD_LABEL},
      {index: 7, db: 'equip_rm', label: 'Equip R&M', color: '#B6992D'},
      {index: 8, db: 'other_exp', label: 'Other', color: '#F1CE63'},
      {index: 9, db: 'fixed', label: 'Fixed', color: '#499894'},
   ];

   static getOrderedLabels(expType = 'All') {
      if (expType === 'All') return this.order.map(({label}) => label);
      if (expType === CHEM_FAIL_LABEL) return ['Chemicals', 'Failures'];
      if (expType === UTILITY_LABEL) return ['NonElec', 'Electric'];
      if (expType === SWD_LABEL) return ['SWD Truck', 'SWD Fee'];
      return this.order.filter(({label}) => expType === label || expType === label.replace(/\s/g, '')).map(({label}) => label);
   }

   static getOrderedColors(expType = 'All') {
      if (expType === 'All') return this.order.map(({color}) => color);
      if (expType === CHEM_FAIL_LABEL) return ['#F28E2B', '#E15759'];
      if (expType === UTILITY_LABEL) return ['#8CD17D', '#59A14F'];
      if (expType === SWD_LABEL) return ['#4E79A7', '#A0CBE8'];
      return this.order.filter(({label}) => expType === label || expType === label.replace(/\s/g, '')).map(({color}) => color);
   }

   static getOrderedDb(expType = 'All') {
      if (expType === 'All') return this.order.map(({db}) => db);
      if (expType === CHEM_FAIL_LABEL) return ['chemicals', 'failure'];
      if (expType === UTILITY_LABEL) return ['propane', 'electric'];
      if (expType === SWD_LABEL) return ['swd_trucking', 'swd_fees'];
      return this.order.filter(({label}) => expType === label || expType === label.replace(/\s/g, '')).map(({db}) => db);
   }

   static getLabelFromIndex(findIndex = 0) {
      return this.order.filter(({index}) => index === findIndex)[0]['label'];
   }

   // Return the matching color based on db label, or return the requested web color as an override.
   static getColorFromDb(ref = 'ad_tax') {
      let db = this.order.find(i => i.db === ref);
      return db ? db['color'] : ref;
   }
}
