import React from 'react';
import { Box, DropButton, Grid, Text } from 'grommet';
import { Dashboard } from 'grommet-icons';
import { SpeedGauge } from './SpeedGauge';
import { MinMaxFilter } from './MinMaxFilter';

const speedOptions = {
   events: [],
   showMarkers: true,
   // animation: {
   //    animateRotate: false,
   //    animateScale: false,
   // },
   animation: false,
   cutoutPercentage: 90,
   plugins: undefined,
};

const defaults = {
   netBoeFilter: '0',
   netMcfFilter: '0',
   netBoeLessFilter: '10000',
   netMcfLessFilter: '1000',
};

const buildGaugeData = (dataType, totals = {}, companyTotals = {}) => {
   let speed = {
      datasets: [{
         backgroundColor: ['green', 'orange', 'red'],
         borderWidth: 0,
         gaugeData: {
            value: 'NULL',
            valueColor: 'black',
         },
      }],
   };

   switch(dataType) {
      // Prevent negative numbers on the speed dials
      case 'MCF':
         speed.datasets[0].gaugeData.value = totals.total_exp_per_mon_per_mcf ? totals.total_exp_per_mon_per_mcf : 0;
         speed.datasets[0].gaugeLimits = [
            companyTotals.total_exp_per_mon_per_mcf_stdevb15 > 0 ? companyTotals.total_exp_per_mon_per_mcf_stdevb15 : 0,
            companyTotals.total_exp_per_mon_per_mcf_stdevb05 > 0 ? companyTotals.total_exp_per_mon_per_mcf_stdevb05 : 0,
            companyTotals.total_exp_per_mon_per_mcf_stdeva05 > 0 ? companyTotals.total_exp_per_mon_per_mcf_stdeva05 : 0,
            companyTotals.total_exp_per_mon_per_mcf_stdeva15 > 0 ? companyTotals.total_exp_per_mon_per_mcf_stdeva15 : 0,
         ];
         break;
      case 'WELL':
         speed.datasets[0].gaugeData.value = totals.total_exp_per_well ? totals.total_exp_per_well : 0;
         speed.datasets[0].gaugeLimits = [
            companyTotals.total_exp_per_well_stdevb15 > 0 ? companyTotals.total_exp_per_well_stdevb15 : 0,
            companyTotals.total_exp_per_well_stdevb05 > 0 ? companyTotals.total_exp_per_well_stdevb05 : 0,
            companyTotals.total_exp_per_well_stdeva05 > 0 ? companyTotals.total_exp_per_well_stdeva05 : 0,
            companyTotals.total_exp_per_well_stdeva15 > 0 ? companyTotals.total_exp_per_well_stdeva15 : 0,
         ];
         speed.datasets[0].backgroundColor = ['green', 'orange', 'red'];
         break;
      case 'BOEPD':
         speed.datasets[0].gaugeData.value = totals.bopd_per_allwells ? Math.round(totals.bopd_per_allwells * 100) / 100 : 0;
         speed.datasets[0].gaugeLimits = [
            companyTotals.bopd_per_allwellsb15 > 0 ? companyTotals.bopd_per_allwellsb15 : 0,
            companyTotals.bopd_per_allwellsb05 > 0 ? companyTotals.bopd_per_allwellsb05 : 0,
            companyTotals.bopd_per_allwellsa05 > 0 ? companyTotals.bopd_per_allwellsa05 : 0,
            companyTotals.bopd_per_allwellsa15 > 0 ? companyTotals.bopd_per_allwellsa15 : 0,
         ];
         speed.datasets[0].backgroundColor = ['red', 'orange', 'green'];
         break;
      case 'BOE': // Intentional fall through
      default:
         speed.datasets[0].gaugeData.value = totals.total_exp_per_mon_per_boe ? totals.total_exp_per_mon_per_boe : 0;
         speed.datasets[0].gaugeLimits = [
            companyTotals.total_exp_per_mon_per_boe_stdevb15 > 0 ? companyTotals.total_exp_per_mon_per_boe_stdevb15 : 0,
            companyTotals.total_exp_per_mon_per_boe_stdevb05 > 0 ? companyTotals.total_exp_per_mon_per_boe_stdevb05 : 0,
            companyTotals.total_exp_per_mon_per_boe_stdeva05 > 0 ? companyTotals.total_exp_per_mon_per_boe_stdeva05 : 0,
            companyTotals.total_exp_per_mon_per_boe_stdeva15 > 0 ? companyTotals.total_exp_per_mon_per_boe_stdeva15 : 0,
         ];
         break;
   }
   return speed;
};

export const GaugeSet = ({
   totals,
   companyTotals,
   selectedFilter,
   selectedItem,
   activeFilter,
   netBoeFilter,
   netBoeLessFilter,
   netMcfFilter,
   netMcfLessFilter,
   setSt
}) => (
   <Box gridArea='CTGAUGE' align='center'>
   <Text weight='bold' color='#666'>{selectedFilter} {selectedItem}</Text>
   <Grid key='CTGAUGE'
      columns={ [ '50%', '50%' ] }
      rows={[
         '1/2', // 0,0   1,0
         '1/3'  // 0,1   1,1
      ]}
      gap='small'
      areas={[
         { name: 'BOE', start: [0,0], end: [0,0] },
         { name: 'MCF', start: [1,0], end: [1,0] },
         { name: 'WELL', start: [0,1], end: [0,1] },
         { name: 'BOEPD', start: [1,1], end: [1,1] },
      ]}
      fill={true}
   >
      <Box key='BOE' gridArea='BOE' justify='center' align='center'>
         <SpeedGauge
            data={buildGaugeData('BOE', totals, companyTotals)}
            options={speedOptions}
         />
         <Text size='xlarge'>$ / Net BOE
            { activeFilter === 'Lease' ?
            <DropButton icon=<Dashboard/>
               label={netBoeFilter !== defaults.netBoeFilter || netBoeLessFilter !== defaults.netBoeLessFilter ? '...' : null}
               dropContent={
                  <MinMaxFilter
                     val1={netBoeFilter}
                     val2={netBoeLessFilter}
                     step={5}
                     onChangeV1={(v) => setSt({ netBoeFilter: v})}
                     onChangeV2={(v) => setSt({ netBoeLessFilter: v })}
                     reset={() => setSt({
                        netBoeFilter: defaults.netBoeFilter,
                        netBoeLessFilter: defaults.netBoeLessFilter
                     })}
                  />
               }
            />
            :null}
         </Text>
      </Box>
      <Box key='MCF' gridArea='MCF' justify='center' align='center'>
         <SpeedGauge
            data={buildGaugeData('MCF', totals, companyTotals)}
            options={speedOptions}
         />
         <Text size='xlarge'>$ / Net MCF
            { activeFilter === 'Lease' ?
            <DropButton icon=<Dashboard/>
               label={netMcfFilter !== defaults.netMcfFilter || netMcfLessFilter !== defaults.netMcfLessFilter ? '...' : null}
               dropContent={
                  <MinMaxFilter
                     val1={netMcfFilter}
                     val2={netMcfLessFilter}
                     step={0.25}
                     onChangeV1={(v) => setSt({ netMcfFilter: v })}
                     onChangeV2={(v) => setSt({ netMcfLessFilter: v })}
                     reset={() => setSt({
                        netMcfFilter: defaults.netMcfFilter,
                        netMcfLessFilter: defaults.netMcfLessFilter,
                     })}
                  />
               }
            />
            :null}
         </Text>
      </Box>
      <Box key='WELL' gridArea='WELL' justify='center' align='center'>
         <SpeedGauge
            data={buildGaugeData('WELL', totals, companyTotals)}
            options={speedOptions}
         />
         <Text size='xlarge'>$ LOE / Act Well / Mon</Text>
      </Box>
      <Box key='BOEPD' gridArea='BOEPD' justify='center' align='center'>
         <SpeedGauge
            data={buildGaugeData('BOEPD', totals, companyTotals)}
            options={speedOptions}
         />
         <Text size='xlarge'>BOEPD / All Well</Text>
      </Box>
   </Grid>
   </Box>
);

GaugeSet.defaultProps = {
   netBoeFilter: defaults.netBoeFilter,
   netBoeLessFilter: defaults.netBoeLessFilter,
   netMcfFilter: defaults.netMcfFilter,
   netMcfLessFilter: defaults.netMcfLessFilter,
};
