import React from 'react';
import { Banner as GenericBanner } from '../../../shared/Banner';
import InfoHelp from '../infoHelp.md';

export const Banner = ({
   isLoading,
   companyId,
   handleCompanyChange,
   title
}) => (
   <GenericBanner
      title={title}
      markdown={InfoHelp}
      isLoading={isLoading}
      companyId={companyId}
      handleCompanyChange={handleCompanyChange}
   />
);
