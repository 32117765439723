import React, { Component } from 'react';
import { Box, Button, Heading, Text } from 'grommet';
import { Form, PasswordInputField } from 'grommet-controls';
import { Alert } from 'grommet-icons';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';

import { Banner } from '../../shared/Banner';
import { sendNotification, clearNotification } from '../../api/notification';


const RESET_MUTATION = gql`
   mutation ResetMutation($token: String!, $password: String!) {
      reset(token: $token, password: $password) {
         token
         message
      }
   }
`;

/**
 * Reset dialog
 */
class Reset extends Component {
   constructor(props, context) {
      super(props, context);

      this._confirm = this._confirm.bind(this);

      // parse the URL for the reset token
      let pathname = window.location.pathname.split('/');
      this.state = {
         token: pathname[pathname.length-1],
         password: '',
         confirmPassword: ''
      }
   }

   // confirmed by server
   _confirm = async data => {
      if (data && data.reset && data.reset.message) {
         this.props.sendNotification(null, null, data.reset.message);
         if (data.reset.message === 'Password reset') {
            this.props.history.push('/login');
         } else {
            this.props.history.push('/forgot');
         }
      }
   }

   render() {
      const { token, password, confirmPassword } = this.state;
      return (
         <Mutation
            mutation={RESET_MUTATION}
            variables={{
               token,
               password: window.btoa(password)
            }}
            onCompleted={data => this._confirm(data)}
         >
         {mutation =>
            (<div>
               <Banner title='Reset Password'/>
               <Box align='center'>
               <Box>
               <Heading>Create your new password</Heading>
               <Form onSubmit={mutation}>
                  <PasswordInputField
                     name='password'
                     label='Password'
                     value={password}
                     onChange={({ target: { value } }) => this.setState({ password: value })}
                  />
                  { password && password.length < 8 ?
                     <Text>Password needs to be at least 8 characters</Text>
                     : null}
                  <PasswordInputField
                     name='confimPassword'
                     label='Confim Password'
                     value={confirmPassword}
                     onChange={({ target: { value } }) => this.setState({ confirmPassword: value })}
                  />
                  {password !== confirmPassword ?
                     <Box direction='row' gap='xsmall'>
                        <Alert color='red'/>
                        <Text>Passwords do not match</Text>
                     </Box>
                     : null}
                  <Button
                     type='submit'
                     label='New Password'
                     disabled={
                        password !== confirmPassword ||
                        password.length < 8
                     }
                  />
               </Form>
               </Box>
               </Box>
            </div>)
         }
         </Mutation>
      )
   }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = { sendNotification, clearNotification };

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
