import React, { useState } from 'react';
import { Box, Button, CheckBox, Stack } from 'grommet';
import { BarChart, ZoomIn } from 'grommet-icons';
import { HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-stacked100';
import { OutlabeledPie } from '../../../shared/OutlabeledPie';
import Fixed, { CHEM_FAIL_LABEL, UTILITY_LABEL, SWD_LABEL } from '../colorConstants';

const horizontalOptions = {
   legend: { display: true, position: 'right' },
   responsive: true,
   maintainAspectRatio: false,
   animation: false,
   scales: {
      xAxes: [{
         stacked: true,
         ticks: { beginAtZero: true, min: 0 },
      }],
      yAxes: [{
         stacked: true,
         ticks: { beginAtZero: true, min: 0 },
      }],
   },
   tooltips: {
      callbacks: {
         title: (i, x) => x.tooltipLabels[i[0].index],
      },
   },
};

const horizontal100Options = {...horizontalOptions,
   plugins: {
      stacked100: { enable: true, replaceTooltipLabel: true },
   },
};

const pieOptions = {
   legend: { display: false },
   zoomOutPercentage: 50,
   animation: false,
   maintainAspectRatio: false,
   plugins: {
      outlabels: {
         // Only display outlabels if the slice is more than 1%
         display: ctx => Math.round(ctx.percent*100) > 1 ? true : false,
         text: '%l %p',
         color: 'white',
         stretch: 40,
         font: {
            resizeable: true,
            minSize: 12,
            maxSize: 18
         }
      },
   }
};

// Sort data[a] and data[b] using @field
const sorter = (a, b, field, numeric = false, asc = true) => {
   const up = asc ? 1 : -1;
   if (numeric) {
      let pa = parseFloat(a[field]);
      let pb = parseFloat(b[field]);
      if (isNaN(pa)) pa = 0;
      if (isNaN(pb)) pb = 0;
      if (pa < pb) return -up;
      if (pa > pb) return up;
      return 0;
   } else {
      if (a[field] < b[field]) return -up;
      if (a[field] > b[field]) return up;
      return 0;
   }
}

export const BarLease = ({
   rows,
   selectedItem,
   selectedExpType,
   handleChartClick,
   handleExpClick,
   clearLeasePie,
   isMobile = false,
}) => {
   let barData;
   const dat = rows.find(e => e.name === selectedItem);
   const leasePieData = !dat ? null :
   {
      labels: Fixed.getOrderedLabels(selectedExpType),
      datasets: [{
         backgroundColor: Fixed.getOrderedColors(selectedExpType),
         data: Fixed.getOrderedDb(selectedExpType).map(x => dat[x]),
      }],
   };
   const [is100Stacked, setIs100Stacked] = useState(false);

   if (!leasePieData) {
      barData = {
         labels: rows.sort((a,b) => sorter(a,b, 'total_exp', true, false)).map(v => rows.length < 21 ? v.name.slice(0,20) : ''),
         tooltipLabels: rows.sort((a,b) => sorter(a,b, 'total_exp', true, false)).map(v => v.name),
         datasets: Fixed.order.filter(i =>
            i.label === selectedExpType ||
            selectedExpType === 'All' ||
            (selectedExpType === CHEM_FAIL_LABEL && (i.label === 'Chemicals' || i.label === 'Failures')) ||
            (selectedExpType === UTILITY_LABEL && (i.label === 'NonElec' || i.label === 'Electric')) ||
            (selectedExpType === SWD_LABEL && (i.label === 'SWD Truck' || i.label === 'SWD Fee'))
         ).map((i) => {
            return {
               label: i.label,
               backgroundColor: i.color,
               data: rows.map(v => v[i.db] >= 0 ? v[i.db] : 0),
            }
         }),
      };
   }

   return (
      <Box key='CTNET' gridArea='CTNET'>
         { barData && !leasePieData && !isMobile ?
            <Stack anchor='bottom-right' fill={true} margin='small'>
               <HorizontalBar
                  id='BOPDLT'
                  data={barData}
                  redraw={true}
                  options={is100Stacked ?
                     {...horizontal100Options, onClick: handleChartClick} :
                     {...horizontalOptions, onClick: handleChartClick}}
               />
               <Box align='end'>
                  <Button icon={<ZoomIn/>}
                     onClick={() => window.open('/chart', '_blank')}
                  />
                  <CheckBox
                     checked={is100Stacked}
                     label='100%'
                     toggle={true}
                     reverse={true}
                     onChange={() => setIs100Stacked(!is100Stacked)}
                  />
               </Box>
            </Stack>
         : null}
         { barData && !leasePieData && isMobile ?
            <Stack anchor='bottom-right' fill={true}>
               <Box width='90%' height='70vh'>
               <HorizontalBar
                  id='BOPDLT'
                  data={barData}
                  redraw={true}
                  options={is100Stacked ?
                     {...horizontal100Options, onClick: handleChartClick} :
                     {...horizontalOptions, onClick: handleChartClick}}
               />
               </Box>
               <Box align='end'>
                  <Button icon={<ZoomIn/>}
                     onClick={() => window.open('/chart', '_blank')}
                  />
                  <CheckBox
                     checked={is100Stacked}
                     label='100%'
                     toggle={true}
                     reverse={true}
                     onChange={() => setIs100Stacked(!is100Stacked)}
                  />
               </Box>
            </Stack>
         : null}
         { leasePieData && !isMobile ?
            <Stack anchor='bottom-right' fill={true}>
               <OutlabeledPie
                  data={leasePieData}
                  options={{...pieOptions,
                     title: {
                        display: true,
                        text: selectedItem,
                        fontSize: 16,
                     },
                     onClick: handleExpClick
                  }}
               />
               <Box>
               <Button icon={<BarChart />}
                  fill={false} margin='small' plain={false}
                  onClick={clearLeasePie}
               />
               </Box>
            </Stack>
         : null}
         { leasePieData && isMobile ?
            <Stack anchor='bottom-right' fill={true}>
               <Box width='90%' height='80vh'>
               <OutlabeledPie
                  data={leasePieData}
                  options={{...pieOptions,
                     title: {
                        display: true,
                        text: selectedItem,
                        fontSize: 16,
                     },
                     onClick: handleExpClick
                  }}
               />
               </Box>
               <Box>
               <Button icon={<BarChart />}
                  fill={false} margin='small' plain={false}
                  onClick={clearLeasePie}
               />
               </Box>
            </Stack>
         : null}
      </Box>
   )
}
