import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, RadioButton } from 'grommet';
import styled from 'styled-components';

const MyButton = styled(Button)`
   border-radius: 0px
`;

export class SubSelector extends Component {
   static propTypes = {
      label: PropTypes.string,
      changeFilter: PropTypes.func,
      disabled: PropTypes.bool,
   }
   static defaultProps = {
      label: '',
   }

   render() {
      const { label, changeFilter, disabled } = this.props;

      return (
         <Box>
         <MyButton
            label={
               <RadioButton name={label} label={label} readOnly={true}
                  checked={false}
               />}
            disabled={disabled}
            onClick={() => changeFilter(label)}
         />
         </Box>
      );
   }
}
