import React, { Component } from 'react';
import ChartComponent from 'react-chartjs-2';
import 'chartjs-plugin-piechart-outlabels';


export class OutlabeledPie extends Component {
   render() {
      return (
         <ChartComponent
            {...this.props}
            ref={ref => this.chartInstance = ref && ref.chartInstance}
            type='outlabeledPie'
         />
      );
   }
}
