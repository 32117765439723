import React from 'react';
import PropTypes from 'prop-types';
import { Notification } from 'grommet-controls';
import { connect } from 'react-redux';
import { clearNotification } from '../api/notification';


const NotificationPop = ({ notification, clearNotification }) => (
   <div>
      { notification.isEmpty ? null :
         <Notification {...notification} onClose={clearNotification} />
      }
   </div>
)

NotificationPop.propTypes = {
   name: PropTypes.string,
   notification: PropTypes.any
}

const mapStateToProps = state => ({
      notification: state.notification
})

const mapDispatchToProps = dispatch => ({
   clearNotification: () => dispatch(clearNotification())
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPop)
