import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Text } from 'grommet';
import { Trash } from 'grommet-icons';
import { NumberInput } from 'grommet-controls';


export class MinMaxFilter extends Component {
   static propTypes = {
      val1: PropTypes.any,
      val2: PropTypes.any,
      step: PropTypes.number,
      onChangeV1: PropTypes.func,
      onChangeV2: PropTypes.func,
      reset: PropTypes.func,
   }

   render() {
      const { val1, val2, step, onChangeV1, onChangeV2, reset } = this.props;
      return (
         <Box align='start'>
            <Text>Min</Text>
            <NumberInput value={val1} max={val2} step={step}
               onChange={({ target: { value }}) => onChangeV1(value)}
            />
            <Text>Max</Text>
            <NumberInput value={val2} min={val1} step={step}
               onChange={({ target: { value }}) => onChangeV2(value)}
            />
            <Box direction='row' align='baseline'>
               <Button
                  icon={<Trash/>}
                  onClick={() => reset()}
               />
               {parseFloat(val2) <= parseFloat(val1) ?
                  <Text color='status-critical'>Min must be lower than Max.</Text>
                  : null}
            </Box>
         </Box>
      )
   }
}
