import React, { Component } from 'react';

import { Accordion, AccordionPanel, Box, Text, DataTable, Grid, Select, Button } from 'grommet';
import { CaretUp, CaretDown, Trash, LinkTop } from 'grommet-icons';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import 'chartjs-plugin-stacked100';
import { connect } from 'react-redux';
import decode from 'jwt-decode';

import { Banner } from './components/Banner';

import { CrossTable } from './components/CrossTable';
import Menu from './components/Menu';
import { SummaryPieChart } from './components/SummaryPieChart';
import { GaugeSet } from './components/GaugeSet';
import { BarLease } from './components/BarLease';
import { SummaryTable } from './components/SummaryTable';

import { loadLifting, loadTotals, loadCompanyTotals, loadCrosstab, loadExpFilter, loadExpType } from './api';
import { AUTH_TOKEN, LEASE_CHART_DATA } from '../../shared/constants';
import Fixed, { CHEM_FAIL_LABEL, UTILITY_LABEL, SWD_LABEL } from './colorConstants';

import { SummaryColumns, CostColumns, ChemColumns, CrosstabColumns2 } from './LiftingColumns';

// Generic rounding to two decimal function
// const rnd = (val, decimal = 100) => isNaN(val) ? '' :
//    (Math.round(val * decimal) / decimal).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

const rnd0 = (val, decimal = 100) => isNaN(val) ? 0 : Math.round(val * decimal) / decimal;

const defaults = {
   netBoeFilter: '0',
   netMcfFilter: '0',
   netBoeLessFilter: '10000',
   netMcfLessFilter: '1000',
};

const StickyGrid = styled(Grid)`
   position: -webkit-sticky;
   position: sticky;
   top: 0;
   background-color: white;
   z-index: 10;
`;

class Lifting extends Component {

   topRef = React.createRef();

   constructor(props) {
      super(props);
      const token = sessionStorage.getItem(AUTH_TOKEN);
      const decodedToken = token ? decode(token) : null;

      this.buildCompanyTotals = this.buildCompanyTotals.bind(this);
      this.buildLeasePie = this.buildLeasePie.bind(this);
      this.buildSelectorPie = this.buildSelectorPie.bind(this);
      this.clearLeasePie = this.clearLeasePie.bind(this);
      this.changeFilter = this.changeFilter.bind(this);
      this.handleSelectorChange = this.handleSelectorChange.bind(this);
      this.handleExpTypeChange = this.handleExpTypeChange.bind(this);
      this.handleCompanyChange = this.handleCompanyChange.bind(this);
      this.sorter = this.sorter.bind(this);
      this.optionFilter = this.optionFilter.bind(this);
      this.optionLeaseFilter = this.optionLeaseFilter.bind(this);
      this.handleSummaryChange = this.handleSummaryChange.bind(this);
      this.handleChartClick = this.handleChartClick.bind(this);
      this.handleExpClick = this.handleExpClick.bind(this);
      this.setSt = this.setSt.bind(this);

      this.state = {
         data: undefined,
         isLoading: false,
         selectedItem: '',
         selectedFilter: 'Company',
         selectedExpType: 'All',
         selectedExpCategory: 'all',
         activeFilter: 'Area',
         colType: 'Lifting Cost Summary',
         // barTitle: { title: { display: true, text: 'Area', fontSize: 16 }},
         companyId: decodedToken && decodedToken.admin &&
            decodedToken.company_id ? decodedToken.company_id*1 : undefined,
         companyTotals: undefined,
         totals: undefined,
         compPieData: undefined,
         selectorPieData: undefined,
         leasePieData: undefined,
         leaseDetail: {},
         activePanel: [0],
         expFilter: undefined,
         netBoeFilter: defaults.netBoeFilter,
         netMcfFilter: defaults.netMcfFilter,
         netBoeLessFilter: defaults.netBoeLessFilter,
         netMcfLessFilter: defaults.netMcfLessFilter,
         useColumns: undefined,
         useColumnsNoSummary: undefined,
         useMobileColumns: undefined,
      }

      this.props.loadLifting(undefined, 'area');
      this.props.loadCompanyTotals(undefined);
      // this was pulling a DB DISTINCT list to match expTypes with the specific chart of account labels
      // this.props.loadExpType(undefined);
   }

   componentDidUpdate(prevProps, prevState) {
      const { data, isLoading, companyTotals, totals, expFilter } = this.props;
      const { isLoading: currentLoading } = this.state;

      if (prevProps.data !== data) {
         this.handleReadCSV(data);
      }
      if (prevProps.isLoading !== isLoading && isLoading !== currentLoading) {
         this.setState({ isLoading: isLoading });
      }
      if (prevProps.companyTotals !== companyTotals) {
         this.buildCompanyTotals(companyTotals);
         this.buildSelectorPie();
      }
      if (prevProps.totals !== totals) {
         this.setState({ totals: totals[0] });
      }
      if (prevProps.expFilter !== expFilter) {
         this.setState({ expFilter });
      }
   }

   handleReadCSV(data) {
      // const { activeFilter, selectedFilter, selectedItem } = this.state;
      const area = this.optionFilter(data, 'area', 'Area');
      const county = this.optionFilter(data, 'cty', 'County');
      const foreman = this.optionFilter(data, 'foreman', 'Foreman');
      const pumper = this.optionFilter(data, 'pumper', 'Pumper');
      // const lease = this.optionFilter(data, 'lease', 'Lease');
      const lease = this.optionLeaseFilter(data, 'lease', 'Lease');

      // Write the chart data to local storage.
      const rows = data ? data : [];
      localStorage.setItem(LEASE_CHART_DATA, JSON.stringify({
         labels: rows.sort((a,b) => this.sorter(a,b, 'total_exp', true, false)).map(v => v.name.slice(0,20)),
         datasets: Fixed.order.map((i) => {
            return {
               label: i.label,
               backgroundColor: i.color,
               data: rows.map(v => v[i.db] >= 0 ? v[i.db] : 0),
            }
         }),
      }));

      this.setState({
         data,
         area,
         county,
         foreman,
         pumper,
         lease,
      }, () => this.buildSelectorPie());
   }

   buildCompanyTotals(companyTotals) {
      const dat = companyTotals[0];
      if (!dat) return;

      const pieData = {
         labels: Fixed.getOrderedLabels(),
         datasets: [{
            backgroundColor: Fixed.getOrderedColors(),
            data: Fixed.getOrderedDb().map(x => dat[x]),
         }],
      };

      const cols =  SummaryColumns({ total_exp_per_mon_per_boe: 100 }, {
         above: dat ? dat.total_exp_per_mon_per_boe_stdeva05 : 0,
         below: dat ? dat.total_exp_per_mon_per_boe_stdevb05 : 0,
      });

      const mobileCols = SummaryColumns({ total_exp_per_mon_per_boe: 100 }, {
         above: dat ? dat.total_exp_per_mon_per_boe_stdeva05 : 0,
         below: dat ? dat.total_exp_per_mon_per_boe_stdevb05 : 0,
      }, true);

      const noSummary = cols.map(i => ({...i, footer: undefined}));

      this.setState({
         compPieData: pieData,
         companyTotals: dat,
         totals: dat,
         useColumns: cols,
         useMobileColumns: mobileCols,
         useColumnsNoSummary: noSummary,
      });
   }

   buildSelectorPie() {
      const { data, activeFilter } = this.state;
      const filterSet = this.state[activeFilter.toLowerCase()];
      if (!data) return;
      let workingData = data.filter(e => filterSet.find(i => i === e.name));

      const pieData = {
         labels: Fixed.getOrderedLabels(),
         datasets: [{
            backgroundColor: Fixed.getOrderedColors(),
            data: Fixed.getOrderedDb().map(x => workingData.reduce((prev, next) => rnd0(prev + next[x]), 0)),
         }],
      };
      this.setState({ selectorPieData: {...pieData} });
   }

   buildLeasePie(leaseName) {
      const { data, selectedExpType } = this.state;
      const dat = data.find(e => e.name === leaseName);
      const pieData = !dat ? null :
      {
         labels: Fixed.getOrderedLabels(selectedExpType),
         datasets: [{
            backgroundColor: Fixed.getOrderedColors(selectedExpType),
            data: Fixed.getOrderedDb(selectedExpType).map(x => dat[x]),
         }],
      };

      this.setState({ leasePieData: pieData });
   }

   clearLeasePie() {
      this.setState({
         leasePieData: false,
         selectedItem: '',
         activePanel: [0]
      });
   }

   changeFilter(activeFilter) {
      if (activeFilter !== this.state.activeFilter) {
         this.setState({
            activeFilter,
            isLoading: true,
            leasePieData: undefined,
            activePanel: [0],
            totals: this.state.companyTotals,
            selectedItem: '',
            selectedFilter: 'Company',
            netBoeFilter: defaults.netBoeFilter,
            netMcfFilter: defaults.netMcfFilter,
            netBoeLessFilter: defaults.netBoeLessFilter,
            netMcfLessFilter: defaults.netMcfLessFilter,
            // barTitle: { title: { display: true, text: activeFilter, fontSize: 16 }},
         }, () => {
            this.props.loadLifting(this.state.companyId, activeFilter.toLowerCase());
         });
      }
   }

   handleSelectorChange(type, arr) {
      this.setState({ [type]: arr }, this.buildSelectorPie);
   }

   handleExpTypeChange(type) {
      const { companyId, selectedItem, activeFilter } = this.state;
      this.setState({ selectedExpType: type },
      () => {
         if (activeFilter === 'Lease') {
            this.props.loadCrosstab(companyId, selectedItem);
            this.props.loadExpFilter(companyId, selectedItem, 'all', 'All');
         }
      });
   }

   handleCompanyChange(id) {
      const { activeFilter } = this.state;
      this.setState({
         companyId: id,
         selectedItem: '',
         leasePieData: undefined,
         activePanel: [0]
      }, () => {
         this.props.loadLifting(id, activeFilter.toLowerCase() );
         this.props.loadCompanyTotals(id);
         // this.props.loadExpType(id);
         // this.props.loadTotals(id, activeFilter.toLowerCase(), );
      });
   }

   handleSummaryChange(colType) {
      // Switch lifting cost table content
      const { companyTotals } = this.state;
      // Moved max calculations to the column defintion area to avoid over calculation
      let useColumns;
      let useColumnsNoSummary;
      let useMobileColumns;
      let maxes;
      switch(colType) {
         case 'Lifting Cost Metrics':
            // Using defined bar maxes vs calculating from data set.
            maxes = {
               tax_lc: 10,
               elecprop_per_bf: 2,
               swd_total_per_bw: 2,
               fixed_lc: 20,
            };
            useColumns = CostColumns(maxes);
            useMobileColumns = CostColumns(maxes, true);
            break;
         case 'Chemical Metrics':
            // Using defined bar maxes vs calculating from data set.
            maxes = {
               chem_emul_per_boe: 1,
               chem_cbs_per_bf: 0.5,
               chemfail_ratio: 5,
               chem_cbs_fail_per_pwell: 10000,
               chem_par_per_boe: 5,
            };
            useColumns = ChemColumns(maxes);
            useMobileColumns = ChemColumns(maxes, true);
            break;
         default:
            // Using defined bar maxes vs calculating from data set.
            maxes = {
               total_exp_per_mon_per_boe: 100,
            };
            useColumns = SummaryColumns(maxes, {
               above: companyTotals ? companyTotals.total_exp_per_mon_per_boe_stdeva05 : 0,
               below: companyTotals ? companyTotals.total_exp_per_mon_per_boe_stdevb05 : 0,
            });
            useMobileColumns = SummaryColumns(maxes, {
               above: companyTotals ? companyTotals.total_exp_per_mon_per_boe_stdeva05 : 0,
               below: companyTotals ? companyTotals.total_exp_per_mon_per_boe_stdevb05 : 0,
            }, true);
      }

      // Column no summary is added to the chart of account table
      useColumnsNoSummary = useColumns.map(i => ({...i, footer: undefined}));

      this.setState({ useColumns, useColumnsNoSummary, useMobileColumns });
   }

   // Pivot based on clicking the chart or table row
   handleChartClick(evt, arr) {
      const { companyId, activeFilter, selectedExpType, selectedExpCategory, data } = this.state;
      // Data table/chart click for a specific lease
      if (evt.type === 'click' && activeFilter === 'Lease' && (evt.datum || (arr && arr.length > 0))) {
         const isExpSelected = selectedExpCategory !== 'all' || selectedExpType !== 'All';
         let lease;
         if (evt.datum) {
            lease = evt.datum.name;
         } else if (arr && arr.length > 0) {
            lease = arr[0]._chart.tooltip._data.tooltipLabels[arr[0]._index];
         }
         this.setState({
            selectedFilter: 'Lease',
            selectedItem: lease,
            isLoading: true,
            activePanel: [1],
            leaseDetail: data.find(x => x.name === lease),
         }, () => {
            this.props.loadTotals(companyId, 'lease', lease);
            this.buildLeasePie(lease);
            if (isExpSelected) {
               let expType = selectedExpType;
               if (expType === CHEM_FAIL_LABEL) expType = 'chemfail';
               if (expType === UTILITY_LABEL) expType = 'utility';
               if (expType === SWD_LABEL) expType = 'swd';
               this.props.loadCrosstab(companyId, lease, expType);
               this.props.loadExpFilter(companyId, lease, selectedExpCategory, expType);
            } else {
               this.props.loadCrosstab(companyId, lease);
               this.props.loadExpFilter(companyId, lease, 'all', 'All');
            }
         });
      } else if (evt.type === 'click' && (evt.datum || (arr && arr.length > 0))) {
         const element = activeFilter.toLowerCase();
         let item;
         if (evt.datum) {
            const cElement = element === 'county' ? 'cty' : element;
            item = evt.datum[cElement];
         } else if (arr && arr.length > 0) {
            item = arr[0]._chart.tooltip._data.tooltipLabels[arr[0]._index];
         }
         this.setState({
            selectedFilter: activeFilter,
            activeFilter: 'Lease',
            selectedItem: item,
            isLoading: true,
            leasePieData: undefined,
            activePanel: [0],
         }, () => {
            this.props.loadLifting(companyId, 'lease');
            this.props.loadTotals(companyId, element, item);
         });
      }
   }

   // Expense click from either lease pie chart or expenses per month data table
   handleExpClick(evt, arr) {
      const { companyId, selectedItem, selectedExpType, selectedExpCategory } = this.state;
      let expCategory = 'all';
      let expType = 'All';
      if (evt.type === 'click' && (evt.datum || (arr && arr.length > 0))) {
         if (evt.datum) {
            expCategory = evt.datum.exp_cat_name;
            if (selectedExpCategory === expCategory) expCategory = 'all';
         } else if (arr && arr.length > 0) {
            // outlabel is null if less than 1%, so we need another way to get it
            expType = Fixed.getLabelFromIndex(arr[0]._index);
            if (expType === 'SWD Truck') expType = 'SWDTruck';
            if (expType === 'SWD Fee') expType = 'SWDFee';
            if (expType === CHEM_FAIL_LABEL) expType = 'chemfail';
            if (expType === UTILITY_LABEL) expType = 'utility';
            if (expType === SWD_LABEL) expType = 'swd';
            if (selectedExpType !== 'All') expType = 'All';
         }
         this.setState({
            selectedExpType: expType,
            selectedExpCategory: expCategory,
            isLoading: true,
            activePanel: [1, 2]
         }, () => {
            this.props.loadCrosstab(companyId, selectedItem, expType);
            this.props.loadExpFilter(companyId, selectedItem, expCategory, expType);
            this.buildLeasePie(selectedItem);
         });
      }
   }

   // Pull select options for @field from the @data
   optionFilter(data = null, field = null, filter = null) {
      // const { activeFilter } = this.state;
      let result = null;
      let useField = 'Lease' === filter ? 'name' : field;
      if (field && data ) {
         result = new Set(data.map(i => i[useField]));
         result = Array.from(result);
         result = result.sort((a,b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
         });
      }
      return result;
   }

   optionLeaseFilter(data = null, field = null, filter = null) {
      const { activeFilter, selectedFilter, selectedItem } = this.state;
      let result = null;
      let useField = activeFilter === filter ? 'name' : field;
      let useSelected = selectedFilter.toLowerCase();
      if (useSelected === 'county') useSelected = 'cty';
      if (field && data) {
         if (selectedFilter !== 'Lease' && activeFilter === 'Lease' && selectedItem !== '') {
            result = new Set(data.filter(i => i[useSelected] === selectedItem).map(i => i[useField]));
         } else {
            result = new Set(data.map(i => i[useField]));
         }
         result = Array.from(result);
         result = result.sort((a,b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
         });
      }
      return result;
   }

   // Sort data[a] and data[b] using @field
   sorter(a, b, field, numeric = false, asc = true) {
      const up = asc ? 1 : -1;
      if (numeric) {
         let pa = parseFloat(a[field]);
         let pb = parseFloat(b[field]);
         if (isNaN(pa)) pa = 0;
         if (isNaN(pb)) pb = 0;
         if (pa < pb) return -up;
         if (pa > pb) return up;
         return 0;
      } else {
         if (a[field] < b[field]) return -up;
         if (a[field] > b[field]) return up;
         return 0;
      }
   }

   setSt(obj = {}) {
      this.setState(obj);
   }

   handleTopClick() {
      this.topRef.current.scrollIntoView();
   }

   render() {
      const { isLoading, companyId, activeFilter, data,
         area, county, foreman,
         pumper, lease, companyTotals,
         compPieData, colType, totals, selectedFilter,
         leasePieData, selectedItem, activePanel,
         expFilter, selectorPieData, selectedExpType,
         netBoeFilter, netMcfFilter, netBoeLessFilter, netMcfLessFilter,
         leaseDetail, useColumns, useColumnsNoSummary, useMobileColumns,
      } = this.state;
      const { crosstab, crossLease } = this.props;

      const filterSet = this.state[activeFilter.toLowerCase()];
      let rows = data ? data.filter(e => filterSet.find(i => i === e.name)) : [];

      // Filter rows based on additional filters.
      if (netBoeFilter !== defaults.netBoeFilter && activeFilter === 'Lease') rows = rows.filter(e => e.total_exp_per_mon_per_boe >= netBoeFilter);
      if (netMcfFilter !== defaults.netMcfFilter && activeFilter === 'Lease') rows = rows.filter(e => e.total_exp_per_mon_per_mcf >= netMcfFilter);
      if (netBoeLessFilter !== defaults.netBoeLessFilter && activeFilter === 'Lease') rows = rows.filter(e => e.total_exp_per_mon_per_boe <= netBoeLessFilter);
      if (netMcfLessFilter !== defaults.netMcfLessFilter && activeFilter === 'Lease') rows = rows.filter(e => e.total_exp_per_mon_per_mcf <= netMcfLessFilter);


      let crossRows = crosstab ? crosstab : [];
      const maxDate = companyTotals ? companyTotals.max_edate : undefined;
      let expTotal = 0;
      crossRows.forEach(x => expTotal += x.m12 + x.m11 + x.m10 + x.m9 + x.m8 + x.m7 + x.m6 + x.m5 + x.m4 + x.m3 + x.m2 + x.m1);

      return (
         <Box ref={this.topRef}>
            <MediaQuery minWidth={1224}>
               <Banner isLoading={isLoading} companyId={companyId}
                  handleCompanyChange={this.handleCompanyChange}
                  title={'Lifting Cost Dashboard'}
               />
            </MediaQuery>
            <MediaQuery maxWidth={1224}>
               <Banner isLoading={isLoading} companyId={companyId}
                  handleCompanyChange={this.handleCompanyChange}
                  title={'Lifting Cost'}
               />
            </MediaQuery>
            <MediaQuery minWidth={1224}>
            <Menu
               key='MENU' girdArea='MENU'
               companyId={companyId}
               activeFilter={activeFilter} changeFilter={this.changeFilter}
               handleSelectorChange={this.handleSelectorChange}
               area={area}
               county={county}
               foreman={foreman}
               pumper={pumper}
               lease={lease}
               selectedExpType={selectedExpType} handleExpTypeChange={this.handleExpTypeChange}
            />
            <StickyGrid
               columns={ [ '30%', '30%', '30%' ] }
               rows={[
                  'medium', //0,0   1,0   2,0
               ]}
               gap='small'
               areas={[
                  { name: 'CTPIE', start: [0,0], end: [0,0] },
                  { name: 'CTGAUGE', start: [1,0], end: [1,0] },
                  { name: 'CTNET', start: [2,0], end: [2,0] },
               ]}
            >
               <SummaryPieChart key='CTPIE' gridArea='CTPIE'
                  compPieData={compPieData}
                  selectorPieData={selectorPieData}
                  activeFilter={activeFilter}
               />
               <GaugeSet gridArea='CTGAUGE'
                  totals={totals}
                  companyTotals={companyTotals}
                  selectedFilter={selectedFilter}
                  selectedItem={selectedItem}
                  activeFilter={activeFilter}
                  netBoeFilter={netBoeFilter}
                  netBoeLessFilter={netBoeLessFilter}
                  netMcfFilter={netMcfFilter}
                  netMcfLessFilter={netMcfLessFilter}
                  setSt={this.setSt}
               />
               <BarLease gridArea='CTNET'
                  rows={rows}
                  leasePieData={leasePieData}
                  selectedItem={selectedItem}
                  selectedExpType={selectedExpType}
                  handleChartClick={this.handleChartClick}
                  handleExpClick={this.handleExpClick}
                  clearLeasePie={this.clearLeasePie}
               />
            </StickyGrid>
            </MediaQuery>
            <MediaQuery maxWidth={1224} orientation={'portrait'}>
               <Box pad='small'>
               Please rotate your device for Landscape viewing.
               </Box>
            </MediaQuery>
            <MediaQuery maxWidth={1224} orientation={'landscape'}>
            <Menu
               key='MENU' girdArea='MENU'
               companyId={companyId}
               activeFilter={activeFilter} changeFilter={this.changeFilter}
               handleSelectorChange={this.handleSelectorChange}
               area={area}
               county={county}
               foreman={foreman}
               pumper={pumper}
               lease={lease}
               selectedExpType={selectedExpType} handleExpTypeChange={this.handleExpTypeChange}
            />
            <Fab
               icon={<LinkTop color='white'/>}
               onClick={() => this.handleTopClick()}
               event='click'
               mainButtonStyles = {{opacity: 0.5}}
            />
               <Box width='90%' height='90%'>
               <GaugeSet
                  totals={totals}
                  companyTotals={companyTotals}
                  selectedFilter={selectedFilter}
                  selectedItem={selectedItem}
                  activeFilter={activeFilter}
                  netBoeFilter={netBoeFilter}
                  netBoeLessFilter={netBoeLessFilter}
                  netMcfFilter={netMcfFilter}
                  netMcfLessFilter={netMcfLessFilter}
                  setSt={this.setSt}
               />
               </Box>
               <Box>
                  <Box direction='row' align='center' pad='small'>
                     <Text>Expense Type:</Text>
                     <Select
                        value={selectedExpType}
                        placeholder={selectedExpType}
                        options={Fixed.extra}
                        children={(option) => <Text>{option.label}</Text>}
                        onChange={({option}) => this.handleExpTypeChange(option.label) }
                     />
                     <Button icon={<Trash/>}
                        fill={false} margin='small' plain={false}
                        disabled={selectedExpType === 'All'}
                        onClick={() => this.handleExpTypeChange('All') }
                     />
                  </Box>
                  <BarLease
                     rows={rows}
                     leasePieData={leasePieData}
                     selectedItem={selectedItem}
                     selectedExpType={selectedExpType}
                     handleChartClick={this.handleChartClick}
                     handleExpClick={this.handleExpClick}
                     clearLeasePie={this.clearLeasePie}
                     isMobile={true}
                  />
               </Box>
               { companyTotals ?
               <SummaryTable rows={rows}
                  handleChartClick={this.handleChartClick}
                  handleSummaryChange={this.handleSummaryChange}
                  useColumns={useMobileColumns}
               /> : <Text>No Summary Data</Text> }
            </MediaQuery>
            <MediaQuery minWidth={1224}>
            <Accordion multiple={true} activeIndex={activePanel}
               onActive={activePanel => this.setState({ activePanel })}>
               <AccordionPanel label={<Box direction='row' gap='small' margin='small'>
                  <Text weight='bold'>{colType}</Text>
                  {activePanel.includes(0) ? <CaretUp/> : <CaretDown/>}
               </Box>}>
                  { companyTotals ?
                  <SummaryTable rows={rows}
                     handleChartClick={this.handleChartClick}
                     handleSummaryChange={this.handleSummaryChange}
                     useColumns={useColumns}
                  /> : <Text>No Summary Data</Text> }
               </AccordionPanel>
               <AccordionPanel label={<Box direction='row' gap='small' margin='small'>
                  <Text weight='bold'>{`Chart of Acct. Monthly Summary ${crossLease ? '('+crossLease+')' : ''}`}</Text>
                  {activePanel.includes(1) ? <CaretUp/> : <CaretDown/>}
                  </Box>}>
                  <Box key='CROSSTAB' margin='xsmall'>
                     {leaseDetail && useColumnsNoSummary ?
                        <DataTable
                           data={leaseDetail} sortable={false}
                           pad={{ header: 'xsmall', body: 'xxsmall' }}
                           columns={useColumnsNoSummary}
                           background={{
                              header: { color: '#499894', dark: true, },
                              body: ['light-1', 'light-3'],
                           }}
                           onClickRow={() => this.setState({activePanel: [0]}, () => this.clearLeasePie())}
                        />
                     : null}
                     {crossRows.length > 0 ?
                        <CrossTable columns={CrosstabColumns2(maxDate, expTotal)}
                           data={crossRows} expFilter={expFilter}/>
                     : null}
                  </Box>
               </AccordionPanel>
            </Accordion>
            </MediaQuery>
         </Box>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      data: state.lifting.data,
      companyTotals: state.lifting.companyTotals,
      totals: state.lifting.totals,
      crosstab: state.lifting.crosstab,
      crossLease: state.lifting.crossLease,
      expFilter: state.lifting.expFilter,
      expType: state.lifting.expType,
      isLoading: state.lifting.isLoading,
   }
}

const mapDispatchToProps = { loadLifting, loadTotals, loadCompanyTotals, loadCrosstab, loadExpFilter, loadExpType }

export default connect(mapStateToProps, mapDispatchToProps)(Lifting);
