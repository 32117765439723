import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, CheckBox, Text, ThemeContext, } from 'grommet';
import MediaQuery from 'react-responsive';

const customKnobTheme = {
   checkBox: {
      toggle: {
         knob: {
            extend: `background: #006666;`
         }
      }
   }
};

export default class ToggleSwitch extends Component {
   static propTypes = {
      text1: PropTypes.any,
      text2: PropTypes.any,
      checked: PropTypes.any,
      onChange: PropTypes.func,
   }

   render() {
      const { text1, text2, checked, onChange, disabled } = this.props;

      return (
         <Box direction='row' gap='small' align='center'>
            <MediaQuery minWidth={1224}>
            <Text>{text1}</Text>
            </MediaQuery>
            <ThemeContext.Extend value={customKnobTheme}>
            <CheckBox
               checked={checked}
               toggle={true}
               disabled={disabled}
               onChange={onChange}
            />
            </ThemeContext.Extend>
            <MediaQuery minWidth={1224}>
            <Text>{text2}</Text>
            </MediaQuery>
            <MediaQuery maxWidth={1224}>
            <Text>{!checked ? text1 : text2}</Text>
            </MediaQuery>
         </Box>
      )
   }
}
