import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from 'grommet';
import { LinkUp, LinkDown } from 'grommet-icons';
import MediaQuery from 'react-responsive';

export default class DeltaCell extends Component {
   static propTypes = {
      diff: PropTypes.any,
      percent: PropTypes.any,
   }

   render() {
      const { diff, percent } = this.props;
      let background = null;
      let icon = ' · ';
      if (diff > 0) {
         background = 'aquamarine';
         icon = <Box pad={{horizontal: 'xxsmall'}}>
            <LinkUp size='small' color='darkgreen' />
            </Box>;
      } else if (diff < 0) {
         background = 'mistyrose';
         icon = <Box pad={{horizontal: 'xxsmall'}}>
            <LinkDown size='small' color='red' />
            </Box>;
      }

      return (
         <Box direction='row' align='center' justify={!isNaN(percent) ? 'end' : 'center'}
            background={background}>
            {diff}
            <MediaQuery minWidth={1224}>
            {icon}
            </MediaQuery>
            <MediaQuery minWidth={1224}>
            {!isNaN(percent) ? `(${percent}%)` : null}
            </MediaQuery>
            <MediaQuery maxWidth={1224}>
               <Text size='small'>
               {!isNaN(percent) ? `(${percent}%)` : null}
               </Text>
            </MediaQuery>
         </Box>
      )
   }
}
