import React, { Component } from 'react';
import { Box, Button, Heading, Text } from 'grommet';
import { Form, TextInputField, MaskedInputField, PasswordInputField } from 'grommet-controls';
import { Alert } from 'grommet-icons';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';

import { Banner } from '../../shared/Banner';
import { REMEMBER_BOOL, REMEMBER_ID } from '../../shared/constants';
import { sendNotification, clearNotification } from '../../api/notification';


const SIGNUP_MUTATION = gql`
   mutation SignupMutation($username: String!, $email: String!, $firstname: String!, $lastname: String!, $phone: String!, $password: String!) {
      signup(username: $username, email: $email, firstname: $firstname, lastname: $lastname, phone: $phone, password: $password) {
         username
         message
      }
   }
`;


/**
 * Signup dialog
 */
class Login extends Component {
   constructor(props, context) {
      super(props, context);

      this._confirm = this._confirm.bind(this);

      this.state = {
         username: '',
         password: '',
         firstname: '',
         lastname: '',
         phone: '',
         email: '',
         confirmPassword: '',
      }
   }

   // confirmed by server
   _confirm = async data => {
      // IF user already exists; notification and go to forgot page.
      if (data && data.signup && data.signup.message) {
         if (data.signup.message === 'User already exists') {
            this.props.sendNotification(null, null, 'Username or email already exists, try resetting a forgotten password.');
            this.props.history.push('/forgot');
         } else {
            this.props.sendNotification(null, null, data.signup.message);
         }
      // IF new user created; store
      } else if (data && data.signup && data.signup.username) {
         this.props.sendNotification(null, 'info', 'New user created.  A PetroBase administrator will need to associate you to your company.');
         localStorage.setItem(REMEMBER_BOOL, true);
         localStorage.setItem(REMEMBER_ID, window.btoa(data.signup.username));
         this.props.history.push('/login');
      }
   }

   render() {
      const { username, password, firstname, lastname, phone, email, confirmPassword } = this.state;

      return (
         <Mutation
            mutation={SIGNUP_MUTATION}
            variables={{
               username,
               password: window.btoa(password),
               firstname,
               lastname,
               phone,
               email
            }}
            onCompleted={data => this._confirm(data)}
         >
         {mutation =>
            (<div>
               <Banner title='User Signup'/>
               <Box align='center'>
               <Box>
               <Heading>Create a new user</Heading>
               <Form onSubmit={mutation}>
                  <TextInputField
                     name='user'
                     label='Username (You may use an email address)'
                     value={username}
                     onChange={({ target: { value } }) => this.setState({ username: value })}
                  />
                  <PasswordInputField
                     name='password'
                     label='Password'
                     value={password}
                     onChange={({ target: { value } }) => this.setState({ password: value })}
                  />
                  { password && password.length < 8 ?
                     <Text>Password needs to be at least 8 characters</Text>
                     : null}
                  <PasswordInputField
                     name='confimPassword'
                     label='Confim Password'
                     value={confirmPassword}
                     onChange={({ target: { value } }) => this.setState({ confirmPassword: value })}
                  />
                  {password !== confirmPassword ?
                     <Box direction='row' gap='xsmall'>
                        <Alert color='red'/>
                        <Text>Passwords do not match</Text>
                     </Box>
                     : null}
                  <TextInputField
                     name='firstname'
                     label='First name'
                     value={firstname}
                     onChange={({ target: { value } }) => this.setState({ firstname: value })}
                  />
                  <TextInputField
                     name='lastname'
                     label='Last name'
                     value={lastname}
                     onChange={({ target: { value } }) => this.setState({ lastname: value })}
                  />
                  <MaskedInputField
                     name='phone'
                     label='Phone number'
                     value={phone}
                     onChange={({ target: { value } }) => this.setState({ phone: value })}
                     showMask={false}
                     mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  />
                  <TextInputField
                     name='email'
                     label='Email'
                     value={email}
                     onChange={({ target: { value } }) => this.setState({ email: value })}
                  />
                  <Button
                     type='submit'
                     label='Signup'
                     disabled={
                        password !== confirmPassword ||
                        password.length < 8 ||
                        !username || !email || !phone
                     }
                  />
               </Form>
               </Box>
               </Box>
            </div>)}
         </Mutation>
      );
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { sendNotification, clearNotification };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
