import { sendNotification } from '../../../api/notification';
// GraphQL query files
import { loader } from 'graphql.macro';
const LOAD_PROD_QUERY = loader('./Load.graphql');
const LOAD_PROD_DAILY_QUERY = loader('./LoadDaily.graphql');
const LOAD_MPROD_QUERY = loader('./MLoad.graphql');
const LOAD_MPROD_MONTHLY_QUERY = loader('./LoadMonthly.graphql');

const Tableau20 = ['#4E79A7', '#A0CBE8', '#F28E2B', '#FFBE7D', '#59A14F', '#8CD17D', '#B6992D', '#F1CE63', '#499894', '#86BCB6', '#E15759', '#FF9D9A', '#79706E', '#BAB0AC', '#D37295', '#FABFD2', '#B07AA1', '#D4A6C8', '#9D7660', '#D7B5A6'];


// Actions
const LOAD = 'production/LOAD';
const LOAD_DAILY = 'production/LOAD_DAILY';
const SET_LOADING = 'production/SET_LOADING';
const SET_TAB = 'production/SET_TAB';
const SET_LEASE = 'production/SET_LEASE';


// Reducer
const initialState = {
   data: undefined,
   daily: undefined,
   workingData: undefined,
   workingDaily: undefined,
   isLoading: false,
   selectedLease: '',
   tabType: 'bopd',
   bopdChart: undefined,
   bopdltChart: undefined,
   pieChart: undefined,
   lineChart: undefined,
}
export default function reducer(state = initialState, action = {}) {
   const { type, payload } = action;
   switch (type) {
      case SET_LOADING:
      case SET_TAB:
      case SET_LEASE:
         return { ...state, ...payload }
      case LOAD_DAILY:
         const daily = payload.daily;
         const lineChart = lineDataFilter(daily);
         return {
            ...state,
            ...payload,
            lineChart
         }
      case LOAD:
         const data = payload.data;
         const ptype = optionFilter(data, 'ptype');
         const area = optionFilter(data, 'area');
         const county = optionFilter(data, 'cty');
         const foreman = optionFilter(data, 'foreman');
         const pumper = optionFilter(data, 'pumper');
         const battery = optionFilter(data, 'battery');
         const bopdChart = dataFilter(data, 'bopd_diff', true, true, true, false);
         const bopdltChart = dataFilter(data, 'bopd_ltdiff', true, true, true, false);
         const pieChart = dataFilter(data, 'bopd_cur', true, false, false);
         return {
            ...state,
            ...payload,
            ptype, ptypeOptions: ptype,
            area, areaOptions: area,
            county, countyOptions: county,
            foreman, foremanOptions: foreman,
            pumper, pumperOptions: pumper,
            battery, batteryOptions: battery,
            bopdChart,
            bopdltChart,
            pieChart,
         }
      default: return state;
   }
}


// Action Creators
export function setIsLoading(isLoading = false) {
   return ({
      type: SET_LOADING,
      payload: { isLoading }
   })
}

export function setTab(tabType = 'bopd') {
   return ({
      type: SET_TAB,
      payload: { tabType }
   })
}

export function setLease(selectedLease = '') {
   return ({
      type: SET_LEASE,
      payload: { selectedLease }
   })
}

export function setProd(data) {
   return({
      type: LOAD,
      payload: { workingData: data, data }
   });
}

export function setProdDaily(daily) {
   return({
      type: LOAD_DAILY,
      payload: { workingDaily: daily, daily }
   });
}

export function loadProduction(company_id, aggfield, isMonthly) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.query({
         query: isMonthly ? LOAD_MPROD_QUERY : LOAD_PROD_QUERY,
         variables: { company_id, aggfield }
      }).then((result) => {
         dispatch(setProd(result.data.prod));
         return store.apollo.query({
            query: isMonthly ? LOAD_MPROD_MONTHLY_QUERY : LOAD_PROD_DAILY_QUERY,
            variables: { company_id, aggfield }
         }).then((result2) => {
            dispatch(setProdDaily(result2.data.prod));
            dispatch(setIsLoading());
         }, (error) => {
            let msg = `Cannot get Daily Production.  ${error.message}`;
            dispatch(sendNotification(msg));
            dispatch(setIsLoading());
         })
      }, (error) => {
         let msg = `Cannot get Production.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      });
   }
}

// Pull select options for @field from the @data
function optionFilter(data = null, field = null) {
   let result = null;
   if (field && data ) {
      result = new Set(data.map(i => i[field]));
      result = Array.from(result);
      result = result.sort((a,b) => {
         if (a < b) return -1;
         if (a > b) return 1;
         return 0;
      });
   }
   return result;
}

// Sort data[a] and data[b] using @field
function sorter(a, b, field, numeric = false, asc = true) {
   const up = asc ? 1 : -1;
   if (numeric) {
      if (parseFloat(a[field]) < parseFloat(b[field])) return -up;
      if (parseFloat(a[field]) > parseFloat(b[field])) return up;
      return 0;
   } else {
      if (a[field] < b[field]) return -up;
      if (a[field] > b[field]) return up;
      return 0;
   }
}

function dataFilter(workingData = null, field = null, numeric = false, asc = true, filterZero = false, colorize = true) {
   let newData;
   let tcolor = Tableau20;
   if (field && workingData) {
      while (tcolor.length < workingData.length) {
         tcolor = tcolor.concat(Tableau20);
      }
      workingData.sort((a,b) => sorter(a,b, field, numeric, asc));
      newData = {
         labels: workingData.filter(i => filterZero ? Math.round(i[field]) !== 0 : true).map(i => i['lease']),
         datasets: [{
            data: workingData.filter(i => filterZero ? Math.round(i[field]) !== 0 : true).map(i => Math.round( i[field] )),
            backgroundColor: colorize ? tcolor : workingData.filter(i => filterZero ? Math.round(i[field]) !== 0 : true).map(i => Math.round(i[field]) > 0 ? 'green' : 'red')
         }]
      }
   }
   return newData;
}

function lineDataFilter(workingDaily = null, tabType = 'bopd') {
   let newChart;
   let tcolor = Tableau20;
   let dataType = tabType === 'bopd' ? 'oil' : 'gas';

   if (workingDaily) {
      let leases = new Set(workingDaily.map(i => i['lease']));
      let dates = new Set(workingDaily.map(i => i['date']));
      leases = Array.from(leases);
      while (tcolor.length < leases.length) {
         tcolor = tcolor.concat(Tableau20);
      }

      newChart = {
         labels: [...Array.from(dates)],
         datasets: leases.map((i, index) => ({
            label: i,
            backgroundColor: tcolor[index],
            data: workingDaily.filter(x => x['lease'] === i).map(y => y[dataType]),
            pointRadius: 0
         }))
      }
      return newChart;
   }
}
