import { sendNotification } from '../../../api/notification';
// GraphQL query files
import { loader } from 'graphql.macro';
const LOAD_ALL_USER_QUERY = loader('./LoadUsers.graphql');
const LOAD_ALL_COMPANY_QUERY = loader('./LoadCompany.graphql');
const NEW_COMPANY_MUTATE = loader('./NewCompany.graphql');
const ASSOCIATE_USER_MUTATE = loader('./AssociateUser.graphql');
const UNASSOCIATE_USER_MUTATE = loader('./UnassociateUser.graphql');
const SET_LICENSE_START = loader('./SetLicenseStart.graphql');
const SET_LICENSE_EXPIRES = loader('./SetLicenseExpires.graphql');
const SET_LICENSE_STATUS = loader('./SetLicenseStatus.graphql');

// Actions
export const SET_USERS = 'scorecard/admin/SET_USERS';
export const SET_COMPANIES = 'scorecard/admin/SET_COMPANIES';
export const SET_LOADING = 'scorecard/admin/SET_LOADING';


//Reducer
const initialState = {
   companies: [],
   users: [],
   isLoading: false,
};
export default function reducer(state = initialState, action = {}) {
   const { type, payload } = action;
   switch (type) {
      case SET_USERS:
      case SET_COMPANIES:
      case SET_LOADING:
         return {...state, ...payload}
      default: return state
   }
}

// Action creators
export function setUsers(users = []) {
   return({
      type: SET_USERS,
      payload: { users,
         isLoading: false
      }
   });
}

export function setCompanies(companies = []) {
   return({
      type: SET_COMPANIES,
      payload: { companies,
         isLoading: false
      }
   });
}

export function setIsLoading(isLoading = false) {
   return({
      type: SET_LOADING,
      payload: { isLoading }
   });
}

export function loadUsers() {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.query({
         query: LOAD_ALL_USER_QUERY,
         fetchPolicy: 'network-only'
      }).then((result) => {
         dispatch(setUsers(result.data.getAllUsers));
      }, (error) => {
         let msg = `Cannot get Users.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      });
   }
}

export function loadCompanies() {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.query({
         query: LOAD_ALL_COMPANY_QUERY,
         fetchPolicy: 'network-only'
      }).then((result) => {
         dispatch(setCompanies(result.data.getAllCompanies));
      }, (error) => {
         let msg = `Cannot get Companies.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      });
   }
}

export function newCompany(compId, compName) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.mutate({
         mutation: NEW_COMPANY_MUTATE,
         variables: { compId, compName }
      }).then((result) => {
         dispatch(loadCompanies());
      }, (error) => {
         let msg = `Could not create new company.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      });
   }
}

export function associateUser(userId, compId) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.mutate({
         mutation: ASSOCIATE_USER_MUTATE,
         variables: { userId, compId }
      }).then((result) => {
         dispatch(loadUsers());
      }, (error) => {
         let msg = `Could not associate User.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      })
   }
}

export function unassociateUser(userId) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.mutate({
         mutation: UNASSOCIATE_USER_MUTATE,
         variables: { userId }
      }).then((result) => {
         dispatch(loadUsers());
      }, (error) => {
         let msg = `Could not unassociate User.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      })
   }
}

export function setLicenseStart(compId, dateStart) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.mutate({
         mutation: SET_LICENSE_START,
         variables: { compId, dateStart }
      }).then((result) => {
         dispatch(loadCompanies());
      }, (error) => {
         let msg = `Could not set License start.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      })
   }
}

export function setLicenseExpires(compId, dateEnd) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.mutate({
         mutation: SET_LICENSE_EXPIRES,
         variables: { compId, dateEnd }
      }).then((result) => {
         dispatch(loadCompanies());
      }, (error) => {
         let msg = `Could not set License end.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      })
   }
}

export function setLicenseStatus(compId, status) {
   return function(dispatch, getState) {
      const store = getState();
      dispatch(setIsLoading(true));
      return store.apollo.mutate({
         mutation: SET_LICENSE_STATUS,
         variables: { status, compId }
      }).then((result) => {
         dispatch(loadCompanies());
      }, (error) => {
         let msg = `Could not set License status.  ${error.message}`;
         dispatch(sendNotification(msg));
         dispatch(setIsLoading());
      })
   }
}
