import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Box, Button, DropButton, Text, Markdown, Select } from 'grommet';
import { Spinning } from 'grommet-controls';
import { Menu, User, CircleInformation, Close } from 'grommet-icons';
import decode from 'jwt-decode';
import { AUTH_TOKEN } from './constants';
import styled from 'styled-components';

const StyledDropButton = styled(DropButton)`
   border: 0
   padding: 4px 12px
`;

const StickyButton = styled(Button)`
   position: -webkit-sticky;
   position: sticky;
   top: 0;
`;

export class Banner extends Component {
   static propTypes = {
      title: PropTypes.any,
      isLoading: PropTypes.any,
      companyId: PropTypes.any,
      handleCompanyChange: PropTypes.func,
      markdown: PropTypes.any,
   }
   static defaultProps = {
      isLoading: false,
      markdown: '',
      title: 'OGI Dashboard',
   }

   constructor() {
      super();

      // Get the company list from the JWT
      const token = sessionStorage.getItem(AUTH_TOKEN);
      const decodedToken = token ? decode(token) : null
      const company_list = decodedToken && decodedToken.company_list ? decodedToken.company_list : undefined;
      this.state = {
         open: false,
         isLogin: window.location.pathname === '/login' ||
            window.location.pathname === '/signup' ||
            window.location.pathname === '/forgot' ||
            window.location.pathname.includes('/reset'),
         companyList: company_list,
         companyOptions: company_list,
         infoHelp: '',
      };
   }

   componentDidMount() {
      const { markdown } = this.props;
      if (markdown) {
         let help = fetch(markdown).then(res => res.text());
         Promise.all([help]).then(vals => this.setState({
            infoHelp: vals[0],
         }));
      }
   }

   render() {
      const { title, isLoading, companyId } = this.props;
      const { open, isLogin, companyList, companyOptions, infoHelp } = this.state;
      const token = sessionStorage.getItem(AUTH_TOKEN);
      const decodedToken = token ? decode(token) : null

      let nav = (
         <Box pad='medium' background='dark-2'>
            <Anchor href='production' label='Production Dashboard' />
            <Anchor href='lifting' label='Lifting Cost Dashboard' />
            {decodedToken && decodedToken.admin ?
               <Box margin={{top: 'large'}}>
                  <Anchor href='admin' label='** OGI Admin Page' />
               </Box>
            : null}
         </Box>
      );
      let info = (
         <Box basis='xlarge' overflow='scroll' pad='large' width='xlarge'>
            <StickyButton onClick={() => this.setState({ open: false })}
               icon={<Close />} primary={true} alignSelf='end'
            />
            <Markdown
               components={{
                  'p': {
                     'props': { 'fill': true },
                  }
               }}
            >
               {infoHelp}
            </Markdown>
         </Box>
      );

      return(
         <Box direction='row' border={{'side': 'bottom'}} align='center'>
            {!isLogin ?
            <Box height='xxsmall' pad='xsmall'>
               <StyledDropButton
                  label={<Menu color='brand'/>}
                  dropAlign={{ top: 'bottom', left: 'left' }}
                  dropContent={nav}
               />
            </Box>: null }
            {isLoading ? <Box pad='small'><Spinning /></Box> : null}
            <Text size='xxlarge' weight='bold'>{title}</Text>
            {isLoading ? <Box pad='small'><Spinning /></Box> : null}
            <Box height='xxsmall' align='end' pad='xsmall' flex={true}>
               <Box direction='row'>
                  {infoHelp ?
                  <StyledDropButton
                     label={<CircleInformation color='brand'/>}
                     dropAlign={{ top: 'bottom', right: 'right' }}
                     dropContent={info}
                     open={open}
                     onClose={() => this.setState({ open: false })}
                     onOpen={() => this.setState({ open: true })}
                  /> : null }
                  {token ?
                  <StyledDropButton
                     label={<User color='brand'/>}
                     dropAlign={{ top: 'bottom', right: 'right' }}
                     dropContent={
                        <Box pad='medium' background='dark-2'>
                           <Text>{decodedToken.username}</Text>
                           <Anchor href='logout' label='Logout' />
                           {companyList && companyOptions ?
                              <Select
                                 options={companyOptions}
                                 valueKey='id' labelKey='name'
                                 value={companyList.find(i => i.id === companyId)}
                                 onChange={i => {
                                    this.setState({ companyOptions: companyList})
                                    this.props.handleCompanyChange(i.option.id);
                                 }}
                                 onSearch={searchText => {
                                    const regexp = new RegExp(searchText, 'i');
                                    this.setState({ companyOptions: companyList.filter(i => i.name.match(regexp)) });
                                 }}
                              />
                           : null}
                        </Box>
                     }
                  />
                  : null}
               </Box>
            </Box>
         </Box>
      )
   }
}
