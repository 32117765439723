import React, { Component } from 'react';
import { Box, Button, Heading, Text } from 'grommet';
import { Form, TextInputField } from 'grommet-controls';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';

import { Banner } from '../../shared/Banner';
import { REMEMBER_ID } from '../../shared/constants';
import { sendNotification, clearNotification } from '../../api/notification';


const FORGOT_MUTATION = gql`
   mutation ForgotMutation($username: String!) {
      forgot(username: $username) {
         token
         message
      }
   }
`;

/**
 * Forgot dialog
 */
class Forgot extends Component {
   constructor(props, context) {
      super(props, context);

      this._confirm = this._confirm.bind(this);

      this.state = {
         username: window.atob(localStorage.getItem(REMEMBER_ID))
      }
   }

   // confirmed by server
   _confirm = async data => {
      if (data && data.forgot && data.forgot.message) {
         this.props.sendNotification(null, null, data.forgot.message);
      }
   }

   render() {
      const { username } = this.state;
      return(
         <Mutation
            mutation={FORGOT_MUTATION}
            variables={{ username }}
            onCompleted={data => this._confirm(data)}
         >
         {mutation => (
            <div>
               <Banner title='Forgot Password'/>
               <Box align='center'>
               <Box>
               <Heading>Reset my PetroBase password</Heading>
               <Text>Enter your username or email and you will receive an email with a link  reseting your password.</Text>
               <Box pad='small' border='bottom'/>
               <Form onSubmit={mutation}>
                  <TextInputField
                     name='user'
                     label='Username/Email'
                     value={username}
                     onChange={({ target: { value } }) => this.setState({ username: value })}
                  />
                  <Button
                     type='submit'
                     label='Get Reset Email'
                  />
               </Form>
               </Box>
               </Box>
            </div>
         )}
         </Mutation>
      )

   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { sendNotification, clearNotification };

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
