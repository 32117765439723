import React, { Component } from 'react';

import { Box, CheckBox } from 'grommet';
import { HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-stacked100';

import { Banner } from '../../shared/Banner';
import { LEASE_CHART_DATA } from '../../shared/constants';

const horizontalOptions = {
   legend: { display: true, position: 'right' },
   responsive: true,
   maintainAspectRatio: false,
   animation: false,
   scales: {
      xAxes: [{
         stacked: true,
         ticks: { beginAtZero: true, min: 0 },
      }],
      yAxes: [{
         stacked: true,
         ticks: { beginAtZero: true, min: 0 },
      }],
   },
};

const horizontal100Options = {...horizontalOptions,
   plugins: {
      stacked100: { enable: true, replaceTooltipLabel: true },
   },
};

export default class Chart extends Component {
   constructor(props) {
      super(props);

      let chartData = localStorage.getItem(LEASE_CHART_DATA);
      chartData = chartData ? JSON.parse(chartData) : undefined;

      this.state = {
         chartData,
         is100Stacked: false,
      }
   }

   render () {
      const { chartData, is100Stacked } = this.state;
      const length = chartData && chartData.datasets && chartData.datasets[0] && chartData.datasets[0].data.length;

      return(
         <Box fill={true} height={{min: length*20 + 'px'}}>
         <Banner title='Full Screen Chart'/>
         { chartData ?
            <Box fill={true} align='end'>
               <CheckBox
                  checked={is100Stacked}
                  label='100%'
                  toggle={true}
                  reverse={true}
                  onChange={e => this.setState({is100Stacked: !is100Stacked})}
               />
               <HorizontalBar
                  data={chartData}
                  redraw={true}
                  options={is100Stacked ?
                     {...horizontal100Options} :
                     {...horizontalOptions}}
               />
            </Box>
         : null }
         </Box>
      )
   }
}
