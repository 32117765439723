import React, { Component } from 'react';
import { Anchor, Box, Button, CheckBox, Image, Heading } from 'grommet';
import { Form, TextInputField, PasswordInputField } from 'grommet-controls';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';

import { Banner } from '../../shared/Banner';
import { AUTH_TOKEN, REMEMBER_BOOL, REMEMBER_ID } from '../../shared/constants';
import { sendNotification, clearNotification } from '../../api/notification';

import * as screenshot from './image.png';

import InfoHelp1 from './login_1.md';

const LOGIN_MUTATION = gql`
   mutation LoginMutation($username: String!, $password: String!) {
      login(username: $username, password: $password) {
         token
         message
      }
   }
`;

/**
 * Login dialog
 */
class Login extends Component {
   constructor(props, context) {
      super(props, context);

      this._rememberMe = this._rememberMe.bind(this);
      this._confirm = this._confirm.bind(this);

      this.state = {
         username: '',
         password: '',
         remember: false,
      }
   }

   componentDidMount() {
      const rememberMe = localStorage.getItem(REMEMBER_BOOL);
      const username = window.atob(localStorage.getItem(REMEMBER_ID));
      if (rememberMe) {
         this.setState({
            remember: true,
            username
         });
      }
   }

   // Set or clear the localStorage memory of the username
   _rememberMe() {
      const { username, remember } = this.state;

      if(remember) {
         localStorage.setItem(REMEMBER_BOOL, true);
         localStorage.setItem(REMEMBER_ID, window.btoa(username));
      } else {
         localStorage.removeItem(REMEMBER_BOOL);
         localStorage.removeItem(REMEMBER_ID);
      }
   }

   // Login confirmed by server
   _confirm = async data => {
      this._rememberMe();
      if (data && data.login && data.login.message === 'Success') {
         sessionStorage.setItem(AUTH_TOKEN, data.login.token);
         this.props.clearNotification();
         this.props.history.push('/production');
      } else {
         this.props.sendNotification('', null, data.login.message);
      }
   }

   render() {
      const { username, password, remember } = this.state;
      return (
         <Mutation
            mutation={LOGIN_MUTATION}
            variables={{ username, password: window.btoa(password) }}
            onCompleted={data => this._confirm(data)}
         >
         {mutation =>
            (<div>
               <Banner title='Login' markdown={InfoHelp1}/>
               <Box align='center' direction='row' justify='evenly'>
                  <Box align='center'>
                     {screenshot ?
                     <Box>
                        <MediaQuery maxWidth={1224}>
                           <Anchor href='http://remote.petrobase.pro' icon={<Box height='small' width='small'><Image fit='contain' src={screenshot} /></Box>} />
                        </MediaQuery>
                        <MediaQuery minWidth={1224}>
                           <Anchor href='http://remote.petrobase.pro' icon={<Image fit='contain' src={screenshot}/>} />
                        </MediaQuery>
                     </Box>
                     : null}
                     <Anchor href='http://remote.petrobase.pro' label='Log into PetroBase Pro Host' />
                  </Box>
                  <Box basis='xxsmall' border='all' height='90vh'/>
               <Box>
               <Form onSubmit={mutation}>
                  <MediaQuery minWidth={1224}>
                     <Heading level='3'>PetroBase Pro Online Dashboards</Heading>
                  </MediaQuery>
                  <TextInputField
                     name='user'
                     label='Username/Email'
                     value={username}
                     onChange={({ target: { value } }) => this.setState({ username: value })}
                  />
                  <PasswordInputField
                     name='password'
                     label='Password'
                     value={password}
                     onChange={({ target: { value } }) => this.setState({ password: value })}
                  />
                  <CheckBox
                     label='Remember Me'
                     checked={remember}
                     onChange={() => this.setState({ remember: !remember })}
                  />
                  <Button
                     type='submit'
                     label='Log In'
                  />
               </Form>
               <br />
               <Anchor href='/forgot' label='Forgot Password?'/>
               <Anchor href='/signup' label='Create Account'/>
               </Box>
               </Box>
            </div>
         )}
         </Mutation>
      )
   }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { sendNotification, clearNotification };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
