// Actions
const INIT_APOLLO_CLIENT = 'apollo/INIT_APOLLO_CLIENT';

export default function reducer(state = null, action = {}) {
   const { type, payload } = action;
   switch (type) {
      case INIT_APOLLO_CLIENT:
         return payload.client
      default: return state
   }
}

// Action Creators
export const initClient = client => ({
   type: INIT_APOLLO_CLIENT,
   payload: { client }
})
